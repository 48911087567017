import {
  IDependant,
  IEmployeeErrors,
  IEmployeeFormData,
} from '../../../../redux/slices/singleAddSlice/types';
import theme from '../../../../theme';

export enum SINGLE_ADD_STEPS {
  CHOOSE_POLICY,
  ADD_EMPLOYEE,
  ADD_DEPENDANTS,
  REVIEW_COST,
  SUMMARY,
}

export const mandatoryEmployeeDetailFields: (keyof IEmployeeFormData)[] = [
  'employeeId',
  'dob',
  'firstName',
  'gender',
  'policyStartDate',
];

export const allEmployeeDetailFields: (keyof IEmployeeErrors)[] = [
  'employeeId',
  'dob',
  'firstName',
  'gender',
  'lastName',
  'policyStartDate',
  'mobile',
  'email',
  'ctc',
];

export const SINGLE_ADD_BG_COLORS = [
  theme.colors.surface0,
  theme.colors.surface0,
  theme.colors.white,
  theme.colors.white,
];

export const mandatoryDependantFields: (keyof IDependant)[] = [
  'firstName',
  'dob',
  'gender',
];

export const mandatorySpouseMidtermFields: (keyof IDependant)[] = [
  ...mandatoryDependantFields,
  'kycDocumentURLs',
  'dateOfMarriage',
];
