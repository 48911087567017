import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { ClaimMilestoneEnum, ClaimTagEnum, IClaim } from "../../../adaptars/claims/types";
import LoopApiService from '../../../adaptars/LoopApiService';
import PolicyApi from '../../../adaptars/policyApi';
import { IEndorsements } from '../../../components/pages/Endorsements/ListingPage/types';
import { parseResponse } from '../../../utils/common/Utilities';
import { getFiltersArray } from './utils';

export const fetchAllActivePolicies = createAsyncThunk(
    'FETCH_COMPANY_POLICIES',
    async (companyId: string) => {
        try {
            const result = await PolicyApi.getPolicies(companyId);
            let policies = result?.data;
            policies = policies.filter(
                (policy) => policy.policyPlacementStatus === 'Handover to Ops',
            );

            return {
                companyId,
                policies,
                filters: getFiltersArray(policies),
            };
        } catch (error) {
            throw error;
        }
    },
);
export const fetchCDAccounts = createAsyncThunk(
    'FETCH_CD_ACCOUNTS',
    async (companyId: string) => {
        try {
            const result = await LoopApiService.fetchCDAccounts(companyId);
            return {
                cdAccounts: result?.data || [],
            };
        } catch (error) {
            throw error;
        }
    },
);

export const fetchClaimList = createAsyncThunk(
    'FETCH_CLAIM_LIST',
    async (companyId: string) => {
        try {
            const result = await LoopApiService.fetchClaims(companyId);
            // Only saving claims with active policies in redux state -
            const filteredClaims: IClaim[] = (
                (result?.data?.claims as IClaim[]) || []
            )
                .filter((data) => moment(data.policyEndDate).isAfter(moment()))
                .map((data) =>
                    data.type === 'cashless' && ![ClaimTagEnum.Rejected, ClaimTagEnum.Closed].includes(data?.tag)
                        ? { ...data, status: { milestone: ClaimMilestoneEnum.Settled }, tag: ClaimTagEnum.Paid }
                        : data,
                );
            return {
                claims: filteredClaims,
            };
        } catch (error) {
            throw error;
        }
    },
);

export const fetchEndorsments = createAsyncThunk(
    'FETCH_ENDORSEMENTS_LIST',
    async (companyId: string) => {
        try {
            const [err, result] = await parseResponse(
                LoopApiService.fetchEndorsements(companyId),
            );
            if (err || !result) {
                throw err || Error('Something went wrong');
            }
            // Sorting endos in latest first order -
            return {
                endorsements: (result.data as IEndorsements[])?.sort(
                    (a, b) =>
                        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                ),
            };
        } catch (error) {
            throw error;
        }
    },
);
