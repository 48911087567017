import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';

import { ArrowRightIcon } from '../../../assets/images';
import theme from '../../../theme';
import { redirectToUrl } from '../../../utils/common/common';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import Loader from '../../atoms/Spinner';
import { StyledNoTimelineSpacing } from '../ClaimDetails/styles';
import { AmountBadge } from './AmountBadge';
import {
    StyledButtonContainer,
    StyledClaimsTimelineContainer,
    StyledStatusLineContainer,
    StyledStepContainer,
    StyledStepDescriptionContainer,
    StyledStepsOuterContainer,
    StyledSuccessIcon,
    StyledTimeLine,
} from './styles';
import { ClaimStatusTimelineProps, ITimelineElement } from './types';

const ClaimsTimeline: React.FC<ClaimStatusTimelineProps> = ({ timelineData, approvedAmount, timelineLoading }) => {
    return timelineLoading ? (
        <Loader />
    ) : timelineData ? (
        <StyledClaimsTimelineContainer>
            <StyledStepsOuterContainer>
                {timelineData?.map((element: ITimelineElement, index: number) => {
                    return (
                        <React.Fragment key={element.id}>
                            <StyledStepContainer>
                                <StyledStatusLineContainer>
                                    <StyledSuccessIcon src={element.icon} />
                                    {index < timelineData.length - 1 && (
                                        <StyledTimeLine
                                            $backgroundColor={
                                                element.completed ? theme.colors.loopEmerald : theme.colors.seafoamGray
                                            }
                                        />
                                    )}
                                </StyledStatusLineContainer>
                                <StyledStepDescriptionContainer>
                                    <Typography variant="small" weight="medium" color="primary">
                                        {element.id === 'settled'
                                            ? 'Claim amount paid to employee' +
                                              (approvedAmount ? `: ${formatCurrencyAmount(approvedAmount)}` : '')
                                            : element.title}
                                    </Typography>
                                    {element.description ? (
                                        <Typography variant="extraSmall" weight="regular" color="secondary">
                                            <Typography variant="extraSmall" weight="regular" color="error">
                                                Claim on hold -{' '}
                                            </Typography>
                                            {element.description}
                                        </Typography>
                                    ) : element.date ? (
                                        <Typography variant="extraSmall" weight="regular" color="secondary">
                                            Completed on{' '}
                                            <Typography variant="extraSmall" weight="medium" color="primary">
                                                {element.date}
                                            </Typography>
                                        </Typography>
                                    ) : null}
                                </StyledStepDescriptionContainer>
                                {element.id === 'claim_settled' && element.active && approvedAmount ? (
                                    <AmountBadge approvedAmount={approvedAmount} />
                                ) : null}
                                {element.url ? (
                                    <StyledButtonContainer>
                                        <LoopButton
                                            variant="secondary"
                                            onClick={() => redirectToUrl(element.url ?? '')}
                                            isLoading={false}
                                            size="small"
                                            iconSrc={ArrowRightIcon}
                                            iconOrder="right"
                                        >
                                            {element.ctaTitle}
                                        </LoopButton>
                                    </StyledButtonContainer>
                                ) : null}
                            </StyledStepContainer>
                        </React.Fragment>
                    );
                })}
            </StyledStepsOuterContainer>
        </StyledClaimsTimelineContainer>
    ) : (
        <StyledNoTimelineSpacing />
    );
};

export default ClaimsTimeline;
