import { ITabItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Tabs/types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IClaim } from "../../../../adaptars/claims/types";
import LoopApiService from '../../../../adaptars/LoopApiService';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import { parseResponse } from '../../../../utils/common/Utilities';
import { empTabs, filterOpenClaims } from '../utils';

const useFetchEmployeeClaims = (empId: string) => {
    const [claimsLoading, toggleLoading] = useState<boolean>(false);
    const [claimData, setClaimData] = useState<IClaim[] | null>(null);

    const [employeeTabs, setEmpTabs] = useState<ITabItem[]>(empTabs);
    const selectedCompany = useSelector(selectSelectedCompany);

    useEffect(() => {
        let isMounted = true;

        const fetchEmployeeClaimDetails = async () => {
            if (selectedCompany && empId) {
                toggleLoading(true);

                let error = null;
                let result = null;

                [error, result] = await parseResponse(
                    LoopApiService.fetchClaims(selectedCompany.id, empId),
                );

                if (isMounted) {
                    toggleLoading(false);
                    if (error) {
                        toast.error(error.message || 'Something went wrong!');
                        console.error(error);
                        return;
                    }
                    const empClaimData = result.data?.claims;
                    const updatedTabs = filterOpenClaims(empTabs, empClaimData);
                    setEmpTabs(updatedTabs);
                    setClaimData(empClaimData);
                }
            }
        };

        fetchEmployeeClaimDetails();

        return () => {
            isMounted = false;
        };
    }, [selectedCompany?.id, empId]);

    const memoizedClaimData = useMemo(() => claimData, [claimData]);

    return { claimsLoading, claimData: memoizedClaimData, employeeTabs };
};

export default useFetchEmployeeClaims;
