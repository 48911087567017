import { Chip, Typography } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import { EmptyStateOneGif, TableContentPurpleIcon } from '../../../assets/images';
import Loader from '../../atoms/Spinner';
import {
    StyledClaimChipContainer,
    StyledClaimStatusWrapper,
    StyledDetailContainer,
    StyledDetailsHeader,
    StyledEmptyContainer,
    StyledMainContainer,
    StyledMainWrapper,
    StyledNoTimelineSpacing,
    StyledStatusTimelineContainer,
    StyledTimelineContainer
} from './styles';
import { IClaimDetailsData, IClaimTypes } from './types';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ClaimMilestoneEnum, IClaim } from '../../../adaptars/claims/types';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import useGetClaimTimeline from '../../../utils/hooks/business-hooks/useGetClaimTimeline';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { trackClickEvent } from '../../../utils/segment/utils';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import ClaimStatusOverview from '../../containers/ClaimStatus';
import { LocationState } from '../../pages/EmployeeDetails/types';
import ClaimsTimeline from '../ClaimsTimeline';
import { ITimelineElement } from '../ClaimsTimeline/types';
import CashlessClaim from './CashlessClaim';
import ClaimDetailsAccordion from './ClaimDetailsAccordion';
import { formatClaimTimelineData } from './utils';

const ClaimDetails: React.FunctionComponent<IClaimDetailsData> = ({ claimDetails, claimsLoading }) => {
    const location = useLocation<LocationState>();
    const navigatedClaimId = location.state?.claimId;
    const [currentClaimId, setCurrentClaimId] = React.useState<string>(navigatedClaimId ?? '');
    const trackClick = useSegment('click');

    const { data } = useSelector(selectPoliciesList);
    const filteredClaim = claimDetails.find((claimData) => claimData.id === currentClaimId);

    const handleSelectedClaimChange = (claim: IClaim, index: number) => {
        const claimProperties: Record<string, unknown> = {
            claim_id: claim.id,
            user_id: claim.userId,
        };
        setCurrentClaimId(claim.id);
        trackClick(
            trackClickEvent('CLAIM_' + (index + 1), location.pathname, 'HRD EMP Details Page Events', claimProperties),
        );
    };

    let policyDetails = {
        policyType: '',
        id: '',
    };

    if (filteredClaim) {
        const policyToFind = data.find((policy) => policy.policyNumber === filteredClaim.policyNo);
        policyDetails = {
            policyType: policyToFind?.policyType ?? '',
            id: policyToFind?.id ?? '',
        };
    }

    const { isLoading, claimTimeline } = useGetClaimTimeline(currentClaimId);

    const formattedClaimTimeline: ITimelineElement[] = useMemo(
        () => formatClaimTimelineData(claimTimeline),
        [claimTimeline],
    );

    React.useEffect(() => {
        if (claimDetails.length && !navigatedClaimId) {
            setCurrentClaimId(claimDetails[0].id);
        }
    }, [claimDetails, navigatedClaimId]);

    React.useEffect(() => {
        if (!filteredClaim && claimDetails.length && navigatedClaimId) {
            setCurrentClaimId(claimDetails[0].id);
        }
    }, [filteredClaim, navigatedClaimId, claimDetails]);

    return (
        <>
            {claimsLoading ? (
                <Loader />
            ) : (
                <StyledMainContainer>
                    <StyledDetailsHeader>
                        <img src={TableContentPurpleIcon} alt="" />
                        <Typography variant="medium" weight="medium" color="primary">
                            Track Claims
                        </Typography>
                        <StyledClaimChipContainer>
                            {claimDetails?.map((claim, index) => (
                                <Chip
                                    key={index}
                                    label={`Claim ${index + 1}`}
                                    isActive={currentClaimId === claim.id}
                                    onClick={() => handleSelectedClaimChange(claim, index)}
                                />
                            ))}
                        </StyledClaimChipContainer>
                    </StyledDetailsHeader>
                    {filteredClaim ? (
                        <StyledMainWrapper>
                            <StyledStatusTimelineContainer>
                                <StyledClaimStatusWrapper>
                                    <ClaimStatusOverview
                                        claimData={filteredClaim}
                                        etaStatus={claimTimeline?.eta?.status}
                                    />
                                </StyledClaimStatusWrapper>

                                <StyledTimelineContainer>
                                    {filteredClaim.type === IClaimTypes.reimbursement ? (
                                        <ClaimsTimeline
                                            timelineData={formattedClaimTimeline}
                                            approvedAmount={filteredClaim.approvedAmount}
                                            timelineLoading={isLoading}
                                        />
                                    ) : filteredClaim.type === IClaimTypes.cashless &&
                                        filteredClaim?.status?.milestone !== ClaimMilestoneEnum.Repudiated ? (
                                        <CashlessClaim dischargeDate={filteredClaim.dischargedAt} />
                                    ) : (
                                        <StyledNoTimelineSpacing />
                                    )}
                                </StyledTimelineContainer>
                            </StyledStatusTimelineContainer>
                            <StyledDetailContainer>
                                <ClaimDetailsAccordion claimData={filteredClaim} policyData={policyDetails} />
                            </StyledDetailContainer>
                        </StyledMainWrapper>
                    ) : (
                        <StyledEmptyContainer>
                            <ZeroStateCard
                                title="No claims open for this family!"
                                subtitle="Kudos for keeping everyone in good health!"
                                image={EmptyStateOneGif}
                                note="Note: Claims can take 2-3 days to reflect here"
                            />
                        </StyledEmptyContainer>
                    )}
                </StyledMainContainer>
            )}
        </>
    );
};
export default ClaimDetails;
