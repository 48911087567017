import { IBadgeType } from '@loophealth/loop-ui-web-library/dist/types/atoms/LoopBadge/types';
import { ClaimTagEnum } from "../../../adaptars/claims/types";
import {
    EmptyStateFiveGif,
    EmptyStateThreeGif,
    EmptyStateTwoGif,
    HappyEmployeesGraphicNew,
    ThinkingLaptopGraphic
} from '../../../assets/images';
import { IClaimListStatusState } from './types';

export const ALL_CLAIMS_CONSTANT = 'all';

export const CLAIMS_LIST_STATUS_STATE: IClaimListStatusState = {
    all: {
        emptyState: {
            title: 'No open claims!',
            desc: 'Kudos on keeping your employees healthy!',
            note: 'Claims may take 7-10 days to appear after being raised.',
            image: HappyEmployeesGraphicNew,
            noteType: 'info',
        },
    },
    onhold: {
        emptyState: {
            title: 'No claims on hold!',
            desc: 'No claims are blocked due to pending documents',
            image: EmptyStateTwoGif,
        },
    },
    ongoing: {
        emptyState: {
            title: 'No ongoing claims!',
            desc: 'There are no claims open right now',
            note: 'Note: Claims can take 2-3 days to reflect here',
            image: EmptyStateTwoGif,
        },
    },
    rejected: {
        emptyState: {
            title: 'No rejected claims!',
            desc: 'We do our best to settle all your claims :)',
            image: EmptyStateFiveGif,
        },
    },
    paid: {
        emptyState: {
            title: 'No settled claims yet',
            desc: 'You’ll be able to track all settled claims here once they’re processed by the insurer.',
            image: ThinkingLaptopGraphic,
        },
    },
    closed: {
        emptyState: {
            title: 'No closed claims!',
            desc: 'Your employees have impeccable documentation skills',
            image: EmptyStateThreeGif,
        },
    },
};

export const claimsCountInitialState = {
    ongoing: 0,
    onhold: 0,
    closed: 0,
    rejected: 0,
    paid: 0,
};

export const claimStatusBadgeVariant: Record<ClaimTagEnum, IBadgeType> = {
    ongoing: 'warning',
    onhold: 'error',
    closed: 'error',
    rejected: 'error',
    paid: 'success2',
};
