import { LoopPaginatedTable } from '@loophealth/loop-ui-web-library';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import { isRealtimeTPA } from '../../../utils/common/common';
import useSegment from '../../../utils/segment/hooks/useSegment';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import Loader from '../../atoms/Spinner';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import ClaimListHeader from '../../containers/ClaimListHeader';
import { ALL_CLAIMS_CONSTANT, CLAIMS_LIST_STATUS_STATE } from './constants';
import useFetchClaimList from './hooks/useFetchClaimList';
import useSearchQuery from './hooks/useSearchQuery';
import { StyledClaimsList, StyledEmptyStateContainer } from './styles';
import { IClaimStatusTabs } from './types';
import {
    CLAIM_LIST_HEADERS,
    CLAIM_TABLE_PROCESSOR,
    getClaimsCount,
    getClaimsLastUpdatedAt,
    getFilteredClaims,
    getPolicyListForClaimsDropDown,
    getTableHeader,
    isSelectedPolicyRealtime,
} from './utils';

const ClaimList = () => {
    const history = useHistory();
    const location = useLocation();
    const trackClick = useSegment('click');
    const { isLoading, claims } = useFetchClaimList();
    const { data: policies } = useSelector(selectPoliciesList);
    const policyDropdownFilters = useMemo(() => {
        return getPolicyListForClaimsDropDown(policies);
    }, [policies]);
    const [policyId, setSelectedFilter] = useSearchQuery(
        'policyId',
        policyDropdownFilters?.length ? policyDropdownFilters[0].value : ALL_CLAIMS_CONSTANT,
    );
    const selectedPolicy = useMemo(() => policies.find((policy) => policy.id === policyId), [policyId, policies]);
    const [tab, setSelectedTab] = useSearchQuery<IClaimStatusTabs>('tab', ALL_CLAIMS_CONSTANT);

    const filteredClaims = useMemo(() => {
        return isLoading ? [] : getFilteredClaims(claims, tab, policyId, selectedPolicy?.policyNumber);
    }, [isLoading, claims, tab, selectedPolicy]);

    const claimCounts: Record<IClaimStatusTabs, number> = useMemo(
        () => getClaimsCount(claims, policyId, selectedPolicy?.policyNumber),
        [claims, selectedPolicy],
    );

    const onTableRowClicked = (rowData: Record<string, unknown>) => {
        const claimProperties: Record<string, unknown> = {
            tpa_claim_id: rowData['Claim ID'],
            claim_id: rowData.id,
            claim_type: rowData['Insurance Type'],
            policy_number: rowData['Policy Id'],
            claim_status: rowData.status,
        };
        trackClick(
            trackClickEvent(
                'CLAIM_SELECTION',
                location?.pathname,
                extractPathName(location?.pathname),
                claimProperties,
            ),
        );
        history.push(`employee-details?id=${rowData.userId}`, {
            referrerPage: 'Claims',
            claimId: rowData.id,
        });
    };

    const tableHeader = getTableHeader(tab, filteredClaims.length);
    const { emptyState } = CLAIMS_LIST_STATUS_STATE[tab];

    const handleClaimFilerChange = (policyId: string) => {
        setSelectedFilter(policyId);
        const policyProperties: Record<string, unknown> = {
            policyId: policyId,
        };
        trackClick(
            trackClickEvent(
                'POLICY_SECTION_DROPDOWN',
                location?.pathname,
                extractPathName(location?.pathname),
                policyProperties,
            ),
        );
    };

    useTrackPage(
        {
            policy_number: selectedPolicy?.policyNumber,
            policy_type: selectedPolicy?.policyType,
            selected_tab: tab,
            claim_count: filteredClaims.length,
            active_claims: claimCounts.ongoing,
        },
        !isLoading,
    );

    const handleTabChange = (tab: IClaimStatusTabs) => {
        setSelectedTab(tab);
        const policyProperties: Record<string, unknown> = {
            policyId: tab,
        };
        trackClick(
            trackClickEvent(
                tab.toUpperCase() + '_CTA',
                location?.pathname,
                extractPathName(location?.pathname),
                policyProperties,
            ),
        );
    };
    const zeroStateNote = selectedPolicy?.insurerName && isRealtimeTPA(selectedPolicy?.tpaName) ? '' : emptyState.note;

    const subtitleData = useMemo(() => {
        const filteredClaimsForPolicy = selectedPolicy
            ? claims?.filter((row) => row.policyNo === selectedPolicy?.policyNumber)
            : claims;

        return {
            lastUpdatedAt: getClaimsLastUpdatedAt(filteredClaimsForPolicy),
            isRealtime: isSelectedPolicyRealtime(policies, policyId),
        };
    }, [claims, selectedPolicy, policyId, policies]);

    return (
        <StyledClaimsList>
            <ClaimListHeader
                selectedFilter={policyId}
                policiesOptions={policyDropdownFilters}
                setSelectedFilter={handleClaimFilerChange}
                selectedTab={tab}
                setSelectedTab={handleTabChange}
                claimCounts={claimCounts}
                subtitle={subtitleData}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <LoopPaginatedTable
                    tableHeader={tableHeader}
                    count={filteredClaims.length}
                    rowsPerPage={10}
                    headers={CLAIM_LIST_HEADERS}
                    processor={CLAIM_TABLE_PROCESSOR}
                    onTableRowClicked={onTableRowClicked}
                    data={filteredClaims as any}
                    EmptyState={
                        <StyledEmptyStateContainer>
                            <ZeroStateCard
                                image={emptyState.image}
                                title={emptyState.title}
                                subtitle={emptyState.desc}
                                note={zeroStateNote}
                                noteType={emptyState.noteType}
                            />
                        </StyledEmptyStateContainer>
                    }
                />
            )}
        </StyledClaimsList>
    );
};

export default ClaimList;
