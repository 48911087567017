import flagsmith from 'flagsmith';
import {
  FlagsmithProvider,
  useFlagsmith,
  useFlagsmithLoading,
} from 'flagsmith/react';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../components/atoms/Spinner';
import { selectSelectedCompany } from '../../redux/slices/hrdRevampRedux';
import { selectHrUser } from '../../redux/slices/usersSlice';
import { captureSentryException } from '../sentry';
import { getDeviceDetails } from '../common/common';
import { LOCAL_FLAGS } from '.';
import { isLocalFlags } from '../featureFlag';

export const FlagsmithWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const traits = useMemo(() => ({
    ...getDeviceDetails(),
    env: process.env.REACT_APP_ENV ?? process.env.NODE_ENV,
  }), []);
  return (
    <FlagsmithProvider
      options={{
        // use local flags to prevent api calls while development
        environmentID: isLocalFlags
          ? 'dummy-key-to-fail'
          : process.env.REACT_APP_FLAGSMITH_KEY!,
        onError: (error) => {
          captureSentryException(`Flagsmith error: ${error.message}`, error);
        },
        traits,
        defaultFlags: LOCAL_FLAGS,
        cacheFlags: true,
      }}
      flagsmith={flagsmith}
    >
      <FlagsmithIdentity>{children}</FlagsmithIdentity>
    </FlagsmithProvider>
  );
};

const FlagsmithIdentity: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(selectHrUser).data;
  const selectedCompany = useSelector(selectSelectedCompany);
  const flagsmithState = useFlagsmithLoading();
  const fs = useFlagsmith();

  useEffect(() => {
    if (
      user &&
      selectedCompany &&
      (!fs.initialised ||
        fs.identity !== user.userId ||
        fs.getTrait('company_id') !== selectedCompany?.id)
    ) {
      fs.identify(user.userId, {
        email: user.email,
        mobile: user.mobile,
        company_id: selectedCompany.id,
      });
    }
  }, [user, fs, selectedCompany]);

  return flagsmithState?.isLoading ? <Loader /> : <>{children}</>;
};
