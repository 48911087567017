import { ClaimTagEnum } from "../../../adaptars/claims/types";
import theme from '../../../theme';
import { ALL_CLAIMS_CONSTANT } from '../../pages/ClaimsList/constants';
import { IClaimListHeaderTabs } from './types';

export const CLAIMS_LIST_HEADER_TABS: IClaimListHeaderTabs[] = [
    {
        label: 'All Claims',
        color: theme.colors.consultViolet,
        status: ALL_CLAIMS_CONSTANT,
    },
    { label: 'Paid', color: theme.colors.turquoise, status: ClaimTagEnum.Paid },
    { label: 'Ongoing', color: theme.colors.coverYellow, status: ClaimTagEnum.Ongoing },
    {
        label: 'On Hold',
        color: theme.colors.planRed,
        status: ClaimTagEnum.Onhold,
        tooltip: 'Claims that require additional documents from the employee',
    },
    {
        label: 'Closed',
        color: theme.colors.planRed,
        status: ClaimTagEnum.Closed,
        tooltip:
            'Claims closed by the insurer due to documents not being submitted on time',
    },
    {
        label: 'Rejected',
        color: theme.colors.planRed,
        status: ClaimTagEnum.Rejected,
        tooltip: 'Claims rejected by the insurer',
    },
];
