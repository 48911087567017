import { LoopButton, Shadow, Toast } from '@loophealth/loop-ui-web-library';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import {
  selectAddSelectedPolicyDetails,
  selectActiveDependants,
  selectADSelectedEmployee,
  selectFormSubmissionStatus,
  selectSingleAddCurrentStep,
  selectSingleAddLastStep,
  setFormSubmissionStatus,
  updateCurrentStep,
  updateLastStep,
  setAddDependantErrors,
  selectAddEmployeeDetails,
  selectIsMidtermFlow,
  resetSingleAddDependantsState,
} from '../../../../redux/slices/singleAddSlice';
import {
  ADD_FORM_SUBMISSION_STATUS,
  IEmployeeFormData,
} from '../../../../redux/slices/singleAddSlice/types';
import { StyledBottomNavigation } from '../../../containers/Bulk/styles';
import { isCDLowTrue } from '../../../pages/Endorsements/BulkAddMembers/utils';
import { IError } from '../../../pages/Endorsements/ListingPage/types';
import { SINGLE_ADD_DEPENDENT_STEPS } from '../../../pages/Endorsements/SingleAddDependents/constants';
import { getButtonStates } from '../../../pages/Endorsements/SingleAddDependents/utils';
import CancellationModal from '../../Bulk/CancellationModal';
import { ISingleAddMembersFooter } from '../SingleAddMembersFooter/types';
import { transformIntoPolicyWiseDependantAddData } from '../ReviewCostScreen/utils';
import { submitBulkAddEndorsement } from '../../Bulk/SubmitEndo/utils';
import { validateDependantDetails } from '../../../pages/Endorsements/SingleAddMembers/utils';
import moment from 'moment';
import { findLatestDateFrom } from '../../../../utils/common/Utilities';

const SingleAddDependentsFooter: React.FC<ISingleAddMembersFooter> = ({
  endoCostDetails,
}) => {
  const dispatch = useDispatch();
  const toast = Toast.useToast();
  const isMidterm = useSelector(selectIsMidtermFlow);
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const employeeDetails = useSelector(selectAddEmployeeDetails);
  const searchedEmployee = useSelector(selectADSelectedEmployee);
  const dependentsList = useSelector(selectActiveDependants);
  const isSubmitting = useSelector(selectFormSubmissionStatus);
  const selectedCompany = useSelector(selectSelectedCompany);
  const isOldEmployeeFlow = searchedEmployee !== null;
  const companyId = selectedCompany?.id ?? '';
  const currentStep = useSelector(
    selectSingleAddCurrentStep,
  ) as SINGLE_ADD_DEPENDENT_STEPS;
  const lastStep = useSelector(
    selectSingleAddLastStep,
  ) as SINGLE_ADD_DEPENDENT_STEPS;

  const [lowCDModalVisible, setLowCDModalVisible] = useState(false);

  useEffect(() => {
    dispatch(resetSingleAddDependantsState());
  }, [dispatch, selectedPolicies]);

  const setIsSubmitting = (status: ADD_FORM_SUBMISSION_STATUS) => {
    dispatch(setFormSubmissionStatus(status));
  };

  const setCurrentStep = (
    step: SINGLE_ADD_DEPENDENT_STEPS,
    updateLast = true,
  ) => {
    updateLast && dispatch(updateLastStep(currentStep));
    dispatch(updateCurrentStep(step));
  };

  const {
    isNextEnabled,
    isBackEnabled,
    buttonText,
    backButtonText,
    backButtonVariant,
  } = useMemo(
    () =>
      getButtonStates(
        currentStep,
        selectedPolicies,
        endoCostDetails.totalLives,
        dependentsList.filter((d) => !d.isAlreadyEnrolled),
      ),
    [currentStep, endoCostDetails, selectedPolicies, dependentsList],
  );

  const handleNextStep = () => {
    switch (currentStep) {
      case SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY:
        return setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS);
      case SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS:
        return processDependantDetails();
      case SINGLE_ADD_DEPENDENT_STEPS.REVIEW_COST:
        if (isCDLowTrue(endoCostDetails.endoCostList))
          setLowCDModalVisible(true);
        else submitEndorsementRequest();
    }
  };

  const handleGoBack = () => {
    setCurrentStep(lastStep, false);
  };

  const processDependantDetails = async () => {
    if (isSubmitting) return;
    setIsSubmitting(ADD_FORM_SUBMISSION_STATUS.SECONDARY);
    let selfPolicyStartDate = employeeDetails.policyStartDate ?? new Date();
    if (isOldEmployeeFlow) {
      const selfPolicyStartDateInSeconds =
        searchedEmployee?.policyWiseDeps.find(
          (policy) => policy.policyType === 'GMC',
        )?.policyStartDate?._seconds || 0;
      if (selfPolicyStartDateInSeconds)
        selfPolicyStartDate = moment
          .unix(selfPolicyStartDateInSeconds)
          .toDate();
    }
    let dependantsData = dependentsList;
    if (isMidterm) {
      dependantsData = dependantsData
        .filter((dependant) => !dependant.isAlreadyEnrolled)
        .map((d) => {
          let policyStartDate = selfPolicyStartDate;
          if (d.relationship === 'spouse') {
            policyStartDate = findLatestDateFrom(
              d.dateOfMarriage!,
              selfPolicyStartDate,
            );
          } else if (d.relationship === 'child') {
            policyStartDate = findLatestDateFrom(d.dob!, selfPolicyStartDate);
          }
          return { ...d, policyStartDate };
        });
    } else {
      dependantsData = dependantsData.filter(
        (dependant) =>
          !dependant.isAlreadyEnrolled || dependant.relationship === 'self',
      );
    }
    try {
      const { errors } = await validateDependantDetails(
        companyId,
        selectedPolicies,
        {
          ...employeeDetails,
          policyStartDate: selfPolicyStartDate,
        } as unknown as IEmployeeFormData,
        dependantsData,
        isOldEmployeeFlow,
      );

      dispatch(setAddDependantErrors(errors));
      if (Object.keys(errors).length === 0) {
        setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.REVIEW_COST, false);
      }
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsSubmitting(ADD_FORM_SUBMISSION_STATUS.IDLE);
  };

  const submitEndorsementRequest = async () => {
    if (isSubmitting) return;
    setIsSubmitting(ADD_FORM_SUBMISSION_STATUS.PRIMARY);
    try {
      const policyWiseAddData = transformIntoPolicyWiseDependantAddData(
        selectedPolicies,
        searchedEmployee,
        dependentsList,
      );
      const response = await submitBulkAddEndorsement(
        companyId,
        policyWiseAddData,
      );
      if (!response) throw new Error('Something went wrong');
      else setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.SUMMARY);
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
    setIsSubmitting(ADD_FORM_SUBMISSION_STATUS.IDLE);
  };

  const isNextLoading =
    isSubmitting ===
      (isBackEnabled
        ? ADD_FORM_SUBMISSION_STATUS.PRIMARY
        : ADD_FORM_SUBMISSION_STATUS.SECONDARY) || endoCostDetails.isLoading;

  return (
    <Shadow variant="bottom">
      <StyledBottomNavigation>
        {isBackEnabled && (
          <LoopButton
            size="medium"
            variant={
              isSubmitting || !isNextEnabled ? 'disabled' : backButtonVariant!
            }
            onClick={handleGoBack}
            isLoading={isSubmitting === ADD_FORM_SUBMISSION_STATUS.SECONDARY}
          >
            {backButtonText}
          </LoopButton>
        )}
        <LoopButton
          size="medium"
          variant={isNextEnabled && !isNextLoading ? 'filled' : 'disabled'}
          onClick={handleNextStep}
          isLoading={isNextLoading}
        >
          {buttonText}
        </LoopButton>
      </StyledBottomNavigation>
      <CancellationModal
        isBackBtnModalVisible={false}
        isCancelModalVisible={false}
        setCancelModalVisible={() => {}}
        setBackBtnModalVisible={() => {}}
        onConfirmCancelClick={() => {}}
        lowCDModalVisible={lowCDModalVisible}
        setLowCDModalVisible={setLowCDModalVisible}
        submitEndorsements={submitEndorsementRequest}
        isSubmitting={isSubmitting === ADD_FORM_SUBMISSION_STATUS.PRIMARY}
      />
    </Shadow>
  );
};
export default SingleAddDependentsFooter;
