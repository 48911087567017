import { DateFormatUtils, LoopBadge, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { StyledBullet, StyledClaimStatusWidget, StyledContainer, StyledInfoWrapper } from './styles';
import { ClaimStatusProps } from './types';

import moment from 'moment';
import { ClaimETAStatusEnum, ClaimMilestoneEnum, ClaimTagEnum } from '../../../adaptars/claims/types';
import { toTitleCase } from '../../../utils/common/common';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/common/Utilities';
import { IClaimTypes } from '../ClaimDetails/types';

const ClaimStatusOverview: React.FC<ClaimStatusProps> = ({ claimData, etaStatus }) => {
    const claimAge =
        claimData.lastStatusDate && claimData.claimReceivedAt
            ? moment(claimData.lastStatusDate || new Date()).diff(moment(claimData.claimReceivedAt), 'days') + ` Days`
            : '--';
    const milestone = claimData.status?.milestone!;
    const formatDate = new DateFormatUtils().formatDate;
    return (
        <StyledClaimStatusWidget>
            <StyledContainer>
                <Typography variant="large" weight="regular">
                    Claim Status
                </Typography>
                {claimData?.tag && (
                    <LoopBadge
                        badgeType={
                            claimData?.tag === ClaimTagEnum.Onhold
                                ? 'warning'
                                : [ClaimTagEnum.Closed, ClaimTagEnum.Rejected].includes(claimData?.tag)
                                    ? 'error'
                                    : 'success'}
                        variant="overflow"
                        text={toTitleCase(claimData?.tag)}
                    />
                )}
            </StyledContainer>
            <StyledContainer>
                <StyledInfoWrapper>
                    <Typography variant="small" weight="medium">
                        {claimData.tpaClaimId}
                    </Typography>
                    <Typography variant="small" color="secondary">{`(Claim ID)`}</Typography>
                    {etaStatus && (
                        <LoopBadge
                            badgeType={etaStatus === ClaimETAStatusEnum.Delayed ? 'warning' : 'success'}
                            variant="overflow"
                            text={etaStatus}
                        />
                    )}
                </StyledInfoWrapper>
                <StyledBullet />
                <StyledInfoWrapper>
                    <Typography variant="small" weight="medium">
                        {`${capitalizeFirstLetterOfEachWord(claimData.benificiaryName)}`}
                    </Typography>
                    <Typography variant="small" color="secondary">{`(Patient)`}</Typography>
                </StyledInfoWrapper>
                <StyledBullet />
                <StyledInfoWrapper>
                    <Typography variant="small" weight="medium">
                        {claimData.relationship}
                    </Typography>
                </StyledInfoWrapper>
                <StyledBullet />
                <StyledInfoWrapper>
                    <Typography variant="small" weight="medium">
                        {`${claimData.type} Claim`}
                    </Typography>
                </StyledInfoWrapper>
                {claimData.type === IClaimTypes.reimbursement ? (
                    <>
                        <StyledBullet />
                        <StyledInfoWrapper>
                            <Typography variant="small" weight="medium">
                                {claimAge}
                            </Typography>
                            <Typography variant="small" color="secondary">{`(Claim Age)`}</Typography>
                        </StyledInfoWrapper>
                    </>
                ) : (
                    <>
                        {milestone === ClaimMilestoneEnum.Repudiated ? (
                            <>
                                <StyledBullet />
                                <StyledInfoWrapper>
                                    <Typography variant="small" weight="medium">
                                        {formatDate(claimData.dischargedAt)}
                                    </Typography>
                                    <Typography variant="small" color="secondary">{`(Discharge date)`}</Typography>
                                </StyledInfoWrapper>
                            </>
                        ) : null}
                    </>
                )}
            </StyledContainer>
        </StyledClaimStatusWidget>
    );
};

export default ClaimStatusOverview;
