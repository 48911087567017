import React, { FC, useMemo, memo } from 'react';
import {
  StyledCashlessClaimsCard,
  StyledCashlessClaimsSection,
} from './styles';
import { PieChart, Typography } from '@loophealth/loop-ui-web-library';
import Card from '../../../atoms/Card';
import InfoCard from '../../../atoms/InfoCard';
import {
  EmptyStateFiveGif,
  EmptyStateFourGif,
} from '../../../../assets/images';
import { getCashlessClaimsStatistics } from './utils';
import { ICashlessClaimsMetricProps } from './types';
import CenteredText from '../../../atoms/PieChart/CenterText';
import CustomLegend from '../../../atoms/PieChart/CustomLegend';
import { PieChartContainer } from '../../Employees/Statistics/styles';
import ZeroStateCard from '../../../atoms/ZeroStateCard';
import { useHistory, useLocation } from 'react-router-dom';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';

const CashlessClaims: FC<ICashlessClaimsMetricProps> = ({
  cashlessClaimsSettledCount = 0,
  cashlessClaimsClosedCount = 0,
  rejectedClaims = 0,
  rejectedCashlessClaimsCount = 0,
  rejectedReimbursementClaimsCount = 0,
  policyId,
}) => {
  const cashlessClaims = useMemo(() => {
    return getCashlessClaimsStatistics(
      cashlessClaimsSettledCount,
      cashlessClaimsClosedCount,
    );
  }, [cashlessClaimsSettledCount, cashlessClaimsClosedCount]);

  const history = useHistory();
  const trackClick = useSegment('click');
  const location = useLocation();
  const handleViewRejectedClaims = () => {
    trackClick(
      trackClickEvent(
        'REJECTED_CLAIMS_CTA',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push(`/claims?tab=rejected&policyId=${policyId}`)
  };

  return (
    <StyledCashlessClaimsSection>
      <StyledCashlessClaimsCard>
        <Typography variant="medium" weight="medium">
          Cashless Claims
        </Typography>
        {Number(cashlessClaims.count) !== 0 ? (
          <Card>
            <PieChartContainer>
              <Typography variant="small" weight="medium" color="secondary">
                Settlement Analysis
              </Typography>
              <PieChart
                data={cashlessClaims.data}
                centerText={
                  <CenteredText
                    value={cashlessClaims.count}
                    label="Total Claims"
                  />
                }
                width={180}
                legends="custom"
                renderLegend={(item) => (
                  <CustomLegend
                    color={item.color}
                    count={item.value}
                    percentage={item.percentage}
                    label={item.label}
                  />
                )}
                tooltipText="Claims"
              />
            </PieChartContainer>
          </Card>
        ) : (
          <ZeroStateCard
            title="No cashless claims yet!"
            subtitle="We'll update you as soon as we have one!"
            image={EmptyStateFourGif}
          />
        )}
      </StyledCashlessClaimsCard>

      <StyledCashlessClaimsCard>
        <Typography variant="medium" weight="medium">
          Rejected Claims
        </Typography>
        {/* TODO: Zero state to be handled properly */}
        {rejectedClaims !== 0 ? (
          <InfoCard
            buttonText="See Rejected Claims"
            onClick={handleViewRejectedClaims}
            titleText="claims were rejected!"
            titleCount={rejectedClaims}
            variant="error"
            subtitles={[
              {
                text: 'Reimbursement',
                value: rejectedReimbursementClaimsCount,
              },
              {
                text: 'Cashless',
                value: rejectedCashlessClaimsCount,
              },
            ]}
          />
        ) : (
          <ZeroStateCard
            title="Zero rejected claims!"
            subtitle="We do our best to settle all your claims :)"
            image={EmptyStateFiveGif}
          />
        )}
      </StyledCashlessClaimsCard>
    </StyledCashlessClaimsSection>
  );
};

export default memo(CashlessClaims);
