import { IClaim } from "../../../adaptars/claims/types";
import { groupArrayOfObjectsByKey } from "../../../utils/common/Utilities";
import { ICDAccountUI } from "../../pages/CDAccounts/types";

export const getClaimCountByStatusAndPolicyId = (claims: IClaim[], policyNo: string) => {
    const policyClaims = claims.filter((item) => (item.policyNo === policyNo));
    return groupArrayOfObjectsByKey(
        policyClaims,
        (claim: IClaim) => claim?.tag || '',
    );
}

export const getCdAccountFromList = (cdAccounts: ICDAccountUI[], policyId: string) => {
    return cdAccounts?.find((cdAccount) =>
        cdAccount.policies.includes(policyId)
    )
}
