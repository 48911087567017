import React, { PropsWithChildren } from 'react';
import { selectHrUser } from '../../redux/slices/usersSlice';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../redux/slices/hrdRevampRedux';
import { SegmentProvider } from './hooks/useSegment';

export const SegmentWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const hrData = useSelector(selectHrUser);
  const selectedCompany = useSelector(selectSelectedCompany);

  return (
    <SegmentProvider
      value={{
        traits: {
          user_id: hrData?.data?.userId,
          employer_id: selectedCompany?.id,
          employer_name: hrData?.data?.companyName,
          user_type: 'HR',
          platform: 'hrd',
          company_id: selectedCompany?.id,
          company_name: selectedCompany?.name,
          user_endo_status: '',
          user_created_at: '',
          email: hrData?.data?.email,
          phone: hrData?.data?.mobile,
          login_method: '',
        },
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '',
        screen: {
          height: Math.max(
            document?.documentElement?.clientHeight || 0,
            window?.innerHeight || 0,
          ),
          width: Math.max(
            document?.documentElement?.clientWidth || 0,
            window?.innerWidth || 0,
          ),
        },
      }}
    >
      {children}
    </SegmentProvider>
  );
};
