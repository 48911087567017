import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ClaimTagEnum, IClaim } from "../../../../adaptars/claims/types";
import {
    selectClaimList,
    selectPoliciesList
} from '../../../../redux/slices/hrdRevampRedux';
import { IPolicyListItemType } from '../../../../redux/slices/hrdRevampRedux/types';
import useFetchCDAccounts from '../../../pages/CDAccounts/hooks/useFetchCDAccounts';
import { ICDAccountUI } from '../../../pages/CDAccounts/types';
import { getRecommendedCDBalance } from '../../../pages/CDAccounts/utils';
import { IAlertData } from '../../ClaimAlertItem/types';

export default function useFetchAlerts(policyId?: string) {
    const policyList = useSelector(selectPoliciesList)?.data;
    const { cdAccounts: cdAccountList } = useFetchCDAccounts();
    const claimList = useSelector(selectClaimList)?.data;

    const { policyAlertMap, alerts, alertsCount } = useMemo(() => {
        const policyNo =
            policyId &&
            policyList.find((policy) => policy.id === policyId)?.policyNumber;
        const underQueryClaims = filterUnderQueryClaims(claimList, policyNo);
        const { policyAlertMap, alerts, alertsCount } = prepareAlertsData(
            policyList,
            cdAccountList,
            underQueryClaims,
            policyId,
        );
        return { policyAlertMap, alerts, alertsCount };
    }, [policyList, cdAccountList, claimList, policyId]);

    return {
        alerts,
        alertsCount,
        policyAlertMap,
    };
}

const filterUnderQueryClaims = (claimList: IClaim[], policyNo?: string) => {
    return claimList?.filter(
        (claimData) =>
            claimData.tag === ClaimTagEnum.Onhold &&
            (!policyNo || claimData.policyNo === policyNo),
    );
};

const processLowCdAlerts = (account: ICDAccountUI | undefined) => {
    if (!account) {
        return {
            isEndoBlocked: false,
            isLowCd: true,
        };
    }
    const recommendedCDBalance = getRecommendedCDBalance(account);
    const totalRecommendedCost = account.blockedAmount + recommendedCDBalance;
    const isLowCd = totalRecommendedCost > account.netBalance;
    const isEndoBlocked = account.blockedAmount > account.netBalance;
    return {
        isLowCd,
        isEndoBlocked,
    };
};

const prepareAlertsData = (
    policyList: IPolicyListItemType[],
    accounts: ICDAccountUI[],
    claimList: IClaim[],
    policyId?: string,
) => {
    const policyAlertMap = new Map<string, IAlertData>();
    const alerts: IAlertData[] = [];
    let alertsCount = 0;
    const filteredPolicies = policyId
        ? policyList.filter((policy) => policy.id === policyId)
        : policyList;
    filteredPolicies.forEach((policyData) => {
        const cdAccount = accounts.find((account) =>
            account.policies.includes(policyData.id),
        );
        const { isEndoBlocked, isLowCd } = processLowCdAlerts(cdAccount);
        if (!isLowCd) return;
        const underQueryClaims = claimList.filter(
            (claims) => claims.policyNo === policyData.policyNumber,
        ).length;

        const alertData = {
            id: policyData.id,
            insurerLogo: policyData.insurerLogo,
            nickName: policyData.nickName || '',
            policyType: policyData.policyType,
            policyId: policyData.id,
            cdAccountNumber: cdAccount?.accountNumber ?? '',
            claimAlerts: underQueryClaims,
            CDAccountAlerts: !!(cdAccount || isLowCd),
            isEndoBlocked: isEndoBlocked,
        };
        if (cdAccount || underQueryClaims || isLowCd) {
            policyAlertMap.set(policyData.id, alertData);
            alerts.push(alertData);
            alertsCount =
                alertsCount + underQueryClaims + (cdAccount || isLowCd ? 1 : 0);
        }
    });
    return { policyAlertMap, alerts, alertsCount };
};
