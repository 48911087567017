import { IClaim } from "../../../adaptars/claims/types";

export interface IClaimDetailsData {
    claimDetails: IClaim[];
    claimsLoading: boolean;
}

export interface IClaimTimelineStatus {
    status: string;
    tag: string;
    text: string;
    date?: string;
    url?: string;
}

export enum IClaimTypes {
    reimbursement = 'reimbursement',
    cashless = 'cashless',
}
