export interface IClaim {
    id: string;
    policyNo: string;
    employeeName: string;
    employeeCode: string;
    benificiaryName: string;
    benificiaryGender: string;
    benificiaryAge: number;
    relationship: string;
    type: string;
    claimAmount: number;
    approvedAmount: number;
    sumInsured: number;
    insuranceName: string;
    dischargeSummary?: string;
    disease: string;
    hospital: string;
    hospitalAddress?: string;
    uhid: string;
    tpaName: string;
    tpaClaimId?: string;
    company: string;
    deniedReason?: string;
    policyStartDate: Date;
    policyEndDate: Date;
    admissionAt: Date;
    dischargedAt: Date;
    chequeReceivedAt?: Date;
    claimReceivedAt: Date;
    claimIntimatedAt?: Date;
    claimDeniedAt?: Date;
    status?: IClaimStatus;
    userId: string;
    lastModifiedAt: Date | null;
    lastStatusDate: Date;
    updatedAt: Date;
    requestSubmissionDate: Date;
    tag: ClaimTagEnum
}

export type ClaimUITabsEnum = 'all' | ClaimTagEnum

export enum ClaimTagEnum {
    Closed = 'closed',
    Onhold = 'onhold',
    Paid = 'paid',
    Ongoing = 'ongoing',
    Rejected = 'rejected',
}

export interface IClaimTimeline {
    eta: { status: ClaimETAStatusEnum, showSupport: boolean }
    timelines: IClaimTimelineElement[]
}
export interface IClaimTimelineElement {
    milestone: ClaimMilestoneEnum;
    title: string;
    completed: boolean;
    ongoing: boolean;
    history?: IClaimStatus[];
    completedDate: Date;
    letter?: string;
}
export interface IClaimStatus {
    id?: number;
    status?: string;
    milestone: ClaimMilestoneEnum;
    titleAppOngoing?: string;
    titleApp?: string;
    descApp?: string;
    titleHr?: string;
    underQuery?: boolean;
    internal?: boolean;
    hidden?: boolean;
    isFinal?: boolean;
}
export enum ClaimMilestoneEnum {
    RequestSubmitted = "request_submitted",
    DocumentsReceived = "documents_received",
    DocumentsReviewed = "documents_reviewed",
    Approved = "approved",
    Settled = "settled",
    Closed = "closed",
    Repudiated = "repudiated",
}

export enum ClaimETAStatusEnum {
    OnTime = 'On Time',
    Delayed = 'Delayed'
}
