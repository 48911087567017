import { capitalizeFirstLetterOfEachWordWithoutLower } from './Utilities';
import { REALTIME_TPA } from '../constants';
import { nanoid } from 'nanoid';

export const downloadSignedFile = async (
  URL: string,
  method: string,
  fileName: string,
): Promise<void> => {
  try {
    const file = await fetch(URL, {
      method,
    });
    const link = document.createElement('a');
    link.href = file.url;
    link.download = fileName;
    if (fileName.toLowerCase().endsWith('.pdf')) link.target = '_blank';
    link.click();
    link.remove();
  } catch (error) {
    throw error;
  }
};

export const redirectToUrl = (url: string): void => {
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
  link.remove();
};
export const openFileInNewTab = (file: File | Blob) => {
  const url = URL.createObjectURL(file);
  window.open(url, '_blank');
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 900000);
};

export const getUniqueObjectsByKey = <T>(data: T[], key: keyof T): T[] => {
  let seen: Record<string, boolean> = {};
  return data.filter((item: T) => {
    const value = item[key as keyof T] as string;
    if (!seen[value]) {
      seen[value] = true;
      return true;
    }
    return false;
  });
};

export const toTitleCase = (str: string): string => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
  );
};

export const formatMemberName = (
  firstName: string | undefined | null | unknown,
  lastName?: string | null | unknown,
) => {
  const f = (firstName ?? '').toString().trim();
  const l = (lastName ?? '').toString().trim();
  return capitalizeFirstLetterOfEachWordWithoutLower(`${f} ${l}`.trim());
};

export const isRealtimeTPA = (tpaName?: string) => {
  if (!tpaName) return false;
  for (const tpa of REALTIME_TPA) {
    if (tpaName.toLowerCase().includes(tpa.toLowerCase())) return true;
  }
  return false;
};

export const getDeviceDetails = () => {
  let deviceId = localStorage.getItem('LOOP_DEVICE_ID') || '';
  if (!deviceId) {
    deviceId = nanoid();
    localStorage.setItem('LOOP_DEVICE_ID', deviceId);
  }

  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const vendor = navigator.vendor;
  return {
    user_agent: userAgent,
    platform,
    vendor,
    device_id: deviceId,
  };
};
