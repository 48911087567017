import { useEffect, useState } from 'react';
import { ClaimTagEnum, IClaim } from "../../../../adaptars/claims/types";
import {
    ISiTableRange,
    ISumInsuredRow
} from '../../../containers/Analytics/SumInsuredUtilizedTable/types';
import {
    CASHLESS_STATUS_COUNT_LABEL,
    getClaimAnalysisStatus, REIMBURSMENT_STATUS_COUNT_LABEL
} from '../constant';
import { getTAT } from '../utils';

function useClaimsAnalysis(claims: IClaim[] | null) {
    const [analysis, setAnalysis] = useState<any>(null);

    useEffect(() => {
        function calculateAnalysisMetrics() {
            let zeroToTenDays = 0;
            let elevenToTwentyDays = 0;
            let moreThanTwentyDays = 0;
            const cashlessDataSet: Record<string, number> = {};
            const reimburseDataSet: Record<string, number> = {};
            let rejectedCashlessClaimsCount = 0;
            let rejectedReimbursementClaimsCount = 0;
            let totalApprovedAmount = 0;
            let totalSettledClaims = 0;

            let siBandGroup: Map<number, ISiTableRange> = new Map();

            const employeeCodeToAmountMap = new Map();
            for (const claim of claims || []) {
                const employeeCode = claim.employeeCode;
                const status = claim?.tag
                    ? getClaimAnalysisStatus(claim.type, claim.tag)
                    : null;
                const approvedAmount =
                    status === 'cashlessClaimsSettledCount' ||
                        status === 'settlementSettledCount'
                        ? claim.approvedAmount
                        : 0;
                if (employeeCodeToAmountMap.has(employeeCode)) {
                    const preApproveAmount = employeeCodeToAmountMap.get(employeeCode);
                    employeeCodeToAmountMap.set(
                        employeeCode,
                        preApproveAmount + approvedAmount,
                    );
                } else {
                    employeeCodeToAmountMap.set(employeeCode, approvedAmount);
                }
            }

            const uniqueUserIds = new Set();
            for (const claim of claims || []) {
                const siBand = claim.sumInsured;
                const employeeCode = claim.employeeCode;
                const approvedAmount = Number(
                    employeeCodeToAmountMap.get(employeeCode),
                );
                const limit = siBand * 0.5;

                if (!siBandGroup.has(siBand)) {
                    siBandGroup.set(siBand, {
                        low: { numberOfEmployees: 0, incurredAmount: 0 },
                        mid: { numberOfEmployees: 0, incurredAmount: 0 },
                        high: { numberOfEmployees: 0, incurredAmount: 0 },
                    });
                }

                const bandData = siBandGroup.get(siBand)!;
                const bandType =
                    approvedAmount <= limit
                        ? 'low'
                        : approvedAmount <= siBand * 0.75
                            ? 'mid'
                            : 'high';

                // Sum Insured Utilized - Only for processed claims -
                if (claim?.tag) {
                    const status = getClaimAnalysisStatus(claim.type, claim.tag);
                    if (
                        status === 'cashlessClaimsSettledCount' ||
                        status === 'settlementSettledCount'
                    ) {
                        if (!uniqueUserIds.has(employeeCode)) {
                            bandData[bandType].numberOfEmployees++;
                            bandData[bandType].incurredAmount += approvedAmount;
                        }
                        uniqueUserIds.add(employeeCode);
                    }
                }

                const tAT = getTAT(claim.lastStatusDate, claim.claimReceivedAt);

                const statusTag = claim?.tag;

                if (!statusTag) continue;
                if (claim.type === 'cashless') {
                    const status =
                        CASHLESS_STATUS_COUNT_LABEL[statusTag] ||
                        'cashlessClaimsSettledCount';
                    cashlessDataSet[status] = (cashlessDataSet[status] || 0) + 1;
                    if ([ClaimTagEnum.Rejected, ClaimTagEnum.Closed].includes(statusTag)) {
                        rejectedCashlessClaimsCount++;
                    }
                } else {
                    const status = REIMBURSMENT_STATUS_COUNT_LABEL[statusTag];
                    reimburseDataSet[status] = (reimburseDataSet[status] || 0) + 1;
                    if (statusTag === ClaimTagEnum.Rejected) rejectedReimbursementClaimsCount++;
                    if (statusTag === ClaimTagEnum.Paid) {
                        if (tAT <= 10) {
                            zeroToTenDays++;
                        } else if (tAT > 10 && tAT <= 20) {
                            elevenToTwentyDays++;
                        } else if (tAT > 20) {
                            moreThanTwentyDays++;
                        }
                    }
                }
                statusTag === ClaimTagEnum.Paid && totalSettledClaims++;
                totalApprovedAmount += Number(claim.approvedAmount);
            }
            const csr =
                claims?.length &&
                ((totalSettledClaims / claims?.length) * 100).toFixed(1) + '%';

            const siBandArray: ISumInsuredRow[] = Array.from(siBandGroup.entries())
                .sort(
                    (siBandArrayPre, siBandArrayNxt) =>
                        siBandArrayPre[0] - siBandArrayNxt[0],
                )
                .map(([siBand, result]) => ({
                    siBand,
                    result,
                }));

            return {
                cashlessDataSet: {
                    ...cashlessDataSet,
                    rejectedCashlessClaimsCount,
                    rejectedReimbursementClaimsCount,
                    rejectedClaims: rejectedCashlessClaimsCount + rejectedReimbursementClaimsCount,
                },
                reimburseDataSet: {
                    ...reimburseDataSet,
                    zeroToTenDays,
                    elevenToTwentyDays,
                    moreThanTwentyDays,
                },
                totalClaimed: claims?.length,
                csr,
                claimsValue: totalApprovedAmount,
                siUtilization: siBandArray,
            };
        }

        if (claims) {
            const analysisResult = calculateAnalysisMetrics();
            setAnalysis(analysisResult);
        }
    }, [claims]);

    return analysis;
}

export default useClaimsAnalysis;
