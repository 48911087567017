import { useCallback, useEffect, useState } from 'react';
import { IClaimTimeline } from '../../../adaptars/claims/types';
import LoopApiService from '../../../adaptars/LoopApiService';

interface UseGetClaimTimelineResult {
    claimTimeline?: IClaimTimeline
    isLoading: boolean;
    error: string | null;
    refetch: () => void;
}

const useGetClaimTimeline = (claimId: string): UseGetClaimTimelineResult => {
    const [claimTimeline, setClaimTimeline] = useState<IClaimTimeline>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getClaimTimeline = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await LoopApiService.getClaimTimeline(claimId);
            setClaimTimeline(response.data.timeline as IClaimTimeline);
        } catch (err: unknown) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    }, [claimId]);

    useEffect(() => {
        getClaimTimeline();
    }, [getClaimTimeline]);

    return { claimTimeline, isLoading, error, refetch: getClaimTimeline };
};

export default useGetClaimTimeline;
