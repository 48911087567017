import { useFlags } from 'flagsmith/react';
import { IFlagsmithFeature } from 'flagsmith/types';

enum FlagsmithFlags {
  SINGLE_ADD = 'feature_single_add',
}

// define flags to run locally
const LOCAL_FLAGS: Record<FlagsmithFlags, IFlagsmithFeature> = {
  [FlagsmithFlags.SINGLE_ADD]: { enabled: false, value: '' },
};

const useFeatureFlag = (flag: FlagsmithFlags) => {
  const flags = useFlags([flag]);
  return flags[flag].enabled;
};

const useFeatureFlags = (flags: FlagsmithFlags[]) => {
  return useFlags(flags);
};

const useFeatureFlagValue = (flag: FlagsmithFlags) => {
  const flags = useFlags([flag]);
  return flags[flag].value;
};

export {
  FlagsmithFlags,
  LOCAL_FLAGS,
  useFeatureFlag,
  useFeatureFlagValue,
  useFeatureFlags,
};
