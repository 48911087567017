import { utils } from 'xlsx-js-style';
import {
  DataSchemaBody,
  DataSchemaHeader,
  GeneralInstructionsBody,
  GeneralInstructionsHeader,
  createCell,
  createErrorCell,
  createErrorDescriptionCell,
  createPoliciesBody,
  readmePolicyHeader,
} from './static';
import {
  COMMON_DATA_SCHEMA,
  CONTACT_DATA_SCHEMA,
  CTC_MULTIPLIER_SCHEMA,
  ERROR_DATA_SCHEMA,
  FLAT_SUM_SCHEMA,
  GRADED_TIER_SCHEMA,
  HeaderColors,
} from './constant';
import {
  capitalizeFirstLetterOfEachWord,
  capitalizeFirstLetterOfEachWordWithoutLower,
} from '../../../../../utils/common/Utilities';
import { IPolicyListItemType } from '../../../../../redux/slices/hrdRevampRedux/types';
import { IRejectedAddEntity, IPolicyRejectedEntries } from '../types';
import {
  ErrorKeyMap,
  KEY_NAME_MAP,
  SkipCapitalizeColumns,
} from '../../../../containers/ValidationsScreen/constants';

export const getPolicyType = (policyData: IPolicyListItemType) => {
  let policyType = policyData.policyType;
  const topup = policyData.isTopUpPolicy ? 'TOPUP' : '';
  const parental = policyData.isParentalPolicy ? 'PARENTAL' : '';
  if (parental !== '') {
    policyType += ` ${parental}`;
  }
  if (topup !== '') {
    policyType += ` ${topup}`;
  }
  return policyType;
};

const getFileNames = (data: IPolicyListItemType[]): string[] => {
  return data.map((policy) => {
    const insurerName = (policy.nickName || '').replace(/[\\\/?*\[\]]/g, '_');
    const policyType = getPolicyType(policy);
    const name = policyType + ` (${insurerName})`;
    // excel sheets cannot have name > than 31 chars
    if (name.length > 31) {
      const difference = 31 - name.length;
      if (difference >= insurerName.length) {
        return policyType;
      } else {
        // -3 for ellipsis
        return (
          policyType + ` (${insurerName.slice(0, difference - 3).trim()}...)`
        );
      }
    }
    return name;
  });
};

export const createReadMeDataSheet = (policiesData: IPolicyListItemType[]) => {
  const PoliciesBody = createPoliciesBody(policiesData);
  const wsData = [
    GeneralInstructionsHeader,
    GeneralInstructionsBody,
    [],
    [],
    readmePolicyHeader,
    ...PoliciesBody,
    [],
    [
      {},
      {
        v: 'DATA SCHEMA',
        t: 's',
        s: {
          font: {
            color: { rgb: '000000' },
            bold: true,
          },
          fill: { fgColor: { rgb: 'ffffff' } },
          alignment: {
            vertical: 'center',
            horizontal: 'left',
            wrapText: true,
          },
        },
      },
    ],
    DataSchemaHeader,
    ...DataSchemaBody,
  ];

  return utils.aoa_to_sheet(wsData, {
    cellDates: true,
    dateNF: 'dd mmm yyyy',
  });
};

export const generateErrorTemplate = (
  rejectedEntries: IPolicyRejectedEntries,
  policiesData: IPolicyListItemType[],
) => {
  const wb = utils.book_new();
  const readMeSheet = createReadMeDataSheet(policiesData);
  readMeSheet['!cols'] = [
    { width: 10 },
    { width: 15 },
    { width: 25 },
    { width: 35 },
    { width: 25 },
    { width: 20 },
    { width: 20 },
  ];
  readMeSheet['!rows'] = [{ hpt: 22 }, { hpt: 40 }];
  const merge = [{ s: { r: 1, c: 2 }, e: { r: 1, c: 4 } }];
  readMeSheet['!merges'] = merge;
  utils.book_append_sheet(wb, readMeSheet, 'READ ME');
  getFileNames(policiesData).forEach((sheetName, i) => {
    try {
      const sheet = createErrorSheets(
        policiesData[i].id,
        policiesData[i].sumInsuredStructure,
        !!policiesData[i].hasUniqueSumInsured,
        rejectedEntries,
      );
      if (sheet) {
        sheet['!cols'] = Array.from({ length: 13 }, (_, index) => ({
          width: index === 0 ? 10 : 35,
        }));
        sheet['!rows'] = [{ hpt: 22 }, { hpt: 30 }];
        utils.book_append_sheet(wb, sheet, sheetName);
      }
    } catch (e) {}
  });
  return wb;
};

export const getPolicySheetColumns = (
  sumInsuredStructure: string,
  hasUniqueSumInsured: boolean,
) => {
  const common = COMMON_DATA_SCHEMA.filter(
    (v: { isMandatory: string }) => v.isMandatory !== 'Conditional',
  );
  switch (sumInsuredStructure) {
    case 'CTC Multiplier':
      common.push(...CTC_MULTIPLIER_SCHEMA);
      break;
    case 'Flat Sum Insured':
      common.push(...FLAT_SUM_SCHEMA);
      break;
    case 'Graded Tier':
      common.push(
        ...(hasUniqueSumInsured ? FLAT_SUM_SCHEMA : GRADED_TIER_SCHEMA),
      );
      break;
  }
  common.push(...CONTACT_DATA_SCHEMA, ERROR_DATA_SCHEMA);

  const infoHeader = common.map((column: any) => ({
    v: column.isMandatory,
    t: 's',
    s: {
      font: {
        color: {
          rgb: HeaderColors[column.isMandatory].infoColor,
        },
        italic: true,
      },
      fill: {
        fgColor: {
          rgb: HeaderColors[column.isMandatory].infoFillColor,
        },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  }));
  const columnHeader = common.map((column: any) => ({
    v: column.name,
    t: 's',
    s: {
      font: { color: { rgb: 'ffffff' }, bold: true },
      fill: {
        fgColor: {
          rgb: HeaderColors[column.isMandatory].columnColor,
        },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  }));
  return { infoHeader, columnHeader };
};

const getFormattedItem = (
  item: any,
  sumInsuredStructure: string,
  hasUniqueSumInsured: boolean,
) => {
  let commonFields: {
    's.no': number;
    employee_id: string;
    name: string;
    relationship_to_account_holders: string;
    gender: string;
    policy_start_date: string;
    date_of_birth: string;
    mobile?: string;
    email_address?: string;
    errors?: string;
    error?: string;
    ctc?: string;
    sumInsured?: string;
    grade?: string;
  } = {
    's.no': item.index + 1,
    employee_id: item.employee_id ?? '',
    name: item.name ?? '',
    relationship_to_account_holders: item.relationship_to_account_holders ?? '',
    gender: item.gender ?? '',
    policy_start_date: item.policy_start_date ?? '',
    date_of_birth: item.date_of_birth ?? '',
  };
  if (sumInsuredStructure === 'CTC Multiplier') {
    commonFields.ctc = item.ctc ?? '';
  } else if (sumInsuredStructure === 'Flat Sum Insured') {
    commonFields.sumInsured = item.sumInsured ?? '';
  } else if (sumInsuredStructure === 'Graded Tier') {
    if (!hasUniqueSumInsured) commonFields.grade = item?.grade ?? '';
    commonFields.sumInsured = item.sumInsured ?? '';
  }
  commonFields.mobile = item.mobile ?? '';
  commonFields.email_address = item.email_address ?? '';
  commonFields.errors = item.error ?? '';
  return commonFields;
};

const getFormattedData = (
  apiData: IRejectedAddEntity[] | null,
  sumInsuredStructure: string,
  hasUniqueSumInsured: boolean,
) => {
  return apiData?.map((item: IRejectedAddEntity, index: number) => {
    return getFormattedItem(
      { ...item, index },
      sumInsuredStructure,
      hasUniqueSumInsured,
    );
  });
};

const formatErrorsCell = (value: any) => {
  try {
    const parsedErrors = JSON.parse(value);
    return Object.entries(parsedErrors)
      .map(
        ([key, errorValue]) =>
          `${
            KEY_NAME_MAP[key] ?? key
          } - ${capitalizeFirstLetterOfEachWordWithoutLower(
            errorValue as string,
          )}`,
      )
      .join('\n');
  } catch (error: any) {
    return '';
  }
};

const mapDataToSheet = (formattedData: any[]) => {
  try {
    let result = (formattedData || []).map((entry) => {
      if (!entry || typeof entry !== 'object') {
        return [];
      }
      let errors: Record<string, string> = {};
      try {
        errors = JSON.parse(entry.errors);
      } catch (e) {}

      const entryRow = Object.keys(entry).map((key: any) => {
        if (key === 'errors') {
          return createErrorDescriptionCell(formatErrorsCell(entry[key]));
        } else {
          const isErrored = (ErrorKeyMap[key as string] ?? [])
            .concat(key)
            .some((item) => errors[item]);
          if (isErrored) {
            const v = SkipCapitalizeColumns.includes(key)
              ? entry[key]
              : capitalizeFirstLetterOfEachWord(entry[key]);
            return createErrorCell(v);
          }
          return createCell(entry[key]);
        }
      });
      return entryRow;
    });
    return result;
  } catch (error) {
    return [];
  }
};

export const createErrorSheets = (
  policyId: string,
  sumInsuredStructure: string,
  hasUniqueSumInsured: boolean,
  rejectedEntries: IPolicyRejectedEntries,
) => {
  const sheetHeaders = getPolicySheetColumns(
    sumInsuredStructure,
    hasUniqueSumInsured,
  );
  let processedResponse = null;
  if (rejectedEntries[policyId]) {
    processedResponse = rejectedEntries[policyId];
  }
  const formattedData = getFormattedData(
    processedResponse,
    sumInsuredStructure,
    hasUniqueSumInsured,
  );
  const sheetData = [
    sheetHeaders.infoHeader,
    sheetHeaders.columnHeader,
    ...mapDataToSheet(formattedData as any),
  ];
  return utils.aoa_to_sheet(sheetData, {
    cellDates: true,
    dateNF: 'dd mmm yyyy',
  });
};
