import * as Sentry from '@sentry/react';
import store from '../../redux/store';
import { selectHrUser } from '../../redux/slices/usersSlice';

const initializeSentry = (): void => {
  if (process.env.REACT_APP_ENV === 'production' && !!process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_ENV,
      // having localhost won't harm in production, it will only support local development
      tracePropagationTargets: [
        'localhost',
        process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL as string,
      ],
    });
  }
};

const captureSentryException = (
  message: string,
  error: unknown,
  extra?: Record<string, unknown>,
): Promise<void> =>
  new Promise((res) => {
    let tags = {};
    try {
      const state = store.getState();
      const user = selectHrUser(state)?.data;
      tags = {
        userId: user?.userId,
        email: user?.email,
        companyId: user?.companyId,
        mobile: user?.mobile,
      };
    } catch (e) {}
    Sentry.captureException(new Error(message, { cause: error }), { tags, extra });
    res();
  });

export default initializeSentry;
export { captureSentryException };
