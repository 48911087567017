import { useState, useEffect, useMemo } from 'react';
import { parseResponse } from '../../../../../utils/common/Utilities';
import LoopApiService from '../../../../../adaptars/LoopApiService';
import { processDateFormat } from '../utils';
import moment from 'moment';

const useFetchEndoDateDetails = (companyId: string) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [endoDateDetails, setEndoDateDetails] = useState<string>('');
  useEffect(() => {
    const fetchEndoDateDetails = async () => {
      if (companyId) {
        toggleLoading(true);
        let error = null;
        let result = null;
        [error, result] = await parseResponse(
          LoopApiService.fetchEndorsementsEndDate(companyId),
        );
        toggleLoading(false);
        if (error) return;
        const endoLastDate = moment(
          result.data?.endDate,
          'DD MMM YYYY',
        ).toDate();
        const parseDate = processDateFormat(endoLastDate);
        setEndoDateDetails(parseDate);
      }
    };

    fetchEndoDateDetails();
  }, []);

  const memoizedClaimTimelineData = useMemo(
    () => endoDateDetails,
    [endoDateDetails],
  );

  return {
    endoDateLoading: isLoading,
    endoDateDetails: memoizedClaimTimelineData,
  };
};

export default useFetchEndoDateDetails;
