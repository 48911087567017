import {
  Chip,
  Dialog,
  Form,
  LoopButton,
  Modal,
  Typography,
  UploadPillSelection,
} from '@loophealth/loop-ui-web-library';
import {
  StyledBodyWrapper,
  StyledFileSelectionContainer,
  StyledFileSelectionFooter,
  StyledFooterButton,
  StyledFormElement,
  StyledFormElementFullWidth,
  StyledGenderElement,
  StyledModalBody,
  StyledModalContainer,
  StyledModalFooter,
} from './styles';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { GENDER_DROPDOWN_ITEMS } from '../../Bulk/constants';
import React, { useEffect } from 'react';
import { OPERATION_MODE } from './constants';
import AddAlertError from '../AddAlertError';
import useDependantModalHelper from './hooks/useDependantModalHelper';
import MobileNumberInput from '../../../atoms/MobileNumberInput';
import { TrashErrorIcon } from '../../../../assets/images';

const AddDependantModal = () => {
  const {
    handleSave,
    handleGenderChange,
    handleDateChange,
    handleOnChange,
    handleMobileChange,
    handleSelectedFiles,
    closeModal,
    formData,
    selectedFiles,
    globalErrors,
    buttonText,
    modalTitle,
    isOpen,
    isCtcRequired,
    isEmployee,
    isSpouseMidterm,
    isSaveDisabled,
    errorData,
    mode,
    isValidating,
    reduxFormData,
    setFormData,
    setIsDataUpdated,
    setSelectedFiles,
    toast,
    deleteSelectedFile,
  } = useDependantModalHelper();

  const [deleteFileModalVisible, setDeleteFileModalVisible] =
    React.useState(false);

  const confirmDeleteDocument = () => {
    deleteSelectedFile();
    setDeleteFileModalVisible(false);
  };

  useEffect(() => {
    if (reduxFormData) {
      setFormData(reduxFormData);
      if (reduxFormData.kycDocumentURLs) {
        const tempFiles = [
          {
            name: reduxFormData.kycDocumentURLs,
            size: reduxFormData.kycDocumentSize ?? 0,
          },
        ];
        setSelectedFiles(tempFiles);
      }
    }
    setIsDataUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxFormData]);

  return (
    <>
      <Modal
        isVisible={isOpen}
        setIsVisible={closeModal}
        title={modalTitle}
        hideHeaderBorder
        disablePadding
        overflow="visible"
      >
        <WithThemeProvider>
          <StyledModalContainer $scrollable={isSpouseMidterm}>
            <StyledBodyWrapper>
              <AddAlertError errors={globalErrors} />
              <StyledModalBody>
                {isEmployee && (
                  <>
                    <StyledFormElement
                      $hasError={Boolean(errorData.employeeId)}
                    >
                      <Form.FormLabel
                        id="employeeId"
                        label="Employee ID"
                        required
                      />
                      <Form.Input
                        id="employeeId"
                        name="employeeId"
                        value={formData.employeeId || ''}
                        error={errorData.employeeId}
                        disabled
                        onChange={handleOnChange}
                      ></Form.Input>
                    </StyledFormElement>
                    <StyledFormElement $hasError={Boolean(errorData.dob)}>
                      <Form.FormLabel
                        id="policyStartDate"
                        label="Date of Joining"
                        required
                      />
                      <Form.DatePicker
                        placeholder="Enter Date (DD-MM-YYYY)"
                        value={formData.policyStartDate || null}
                        onChange={(value) =>
                          handleDateChange(value, 'policyStartDate')
                        }
                        displayFormat="DD/MMM/YYYY"
                        error={errorData.policyStartDate}
                        iconOrder="left"
                        maxDate={new Date()}
                        typable
                        fontSize='small'
                      />
                    </StyledFormElement>
                  </>
                )}
                <StyledFormElement $hasError={Boolean(errorData.firstName)}>
                  <Form.FormLabel id="firstName" label="First Name" required />
                  <Form.Input
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    error={errorData.firstName}
                    onChange={handleOnChange}
                  ></Form.Input>
                </StyledFormElement>
                <StyledFormElement $hasError={Boolean(errorData.lastName)}>
                  <Form.FormLabel id="lastName" label="Last Name" />
                  <Form.Input
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    error={errorData.lastName}
                    onChange={handleOnChange}
                  ></Form.Input>
                </StyledFormElement>
                <StyledFormElement $hasError={Boolean(errorData.dob)}>
                  <Form.FormLabel
                    id="dateOfBirth"
                    label="Date of Birth"
                    required
                  />
                  <Form.DatePicker
                    placeholder="Enter Date (DD-MM-YYYY)"
                    value={formData.dob}
                    onChange={(value) => handleDateChange(value, 'dob')}
                    displayFormat="DD/MMM/YYYY"
                    error={errorData.dob}
                    iconOrder="left"
                    maxDate={new Date()}
                    typable
                    fontSize='small'
                  />
                </StyledFormElement>
                {isSpouseMidterm ? (
                  <StyledFormElement
                    $hasError={Boolean(errorData.dateOfMarriage)}
                  >
                    <Form.FormLabel
                      id="dateOfMarriage"
                      label="Date of Marriage"
                      required
                    />
                    <Form.DatePicker
                      placeholder="Enter Date (DD-MM-YYYY)"
                      value={formData.dateOfMarriage || null}
                      onChange={(value) =>
                        handleDateChange(value, 'dateOfMarriage')
                      }
                      displayFormat="DD/MMM/YYYY"
                      error={errorData.dateOfMarriage}
                      iconOrder="left"
                      maxDate={new Date()}
                      typable
                      fontSize='small'
                    />
                  </StyledFormElement>
                ) : (
                  <StyledFormElement $hasError={Boolean(errorData.gender)}>
                    <Form.FormLabel id="gender" label="Gender" required />
                    <StyledGenderElement>
                      {GENDER_DROPDOWN_ITEMS.map((item) => (
                        <Chip
                          label={item.name}
                          key={'LP-' + item.value}
                          isActive={formData.gender === item.value}
                          onClick={() =>
                            handleGenderChange(item.value as 'male' | 'female')
                          }
                        />
                      ))}
                    </StyledGenderElement>
                    <Typography variant="small" color="error">
                      {errorData.gender}
                    </Typography>
                  </StyledFormElement>
                )}

                <StyledFormElement $hasError={Boolean(errorData.mobile)}>
                  <Form.FormLabel id="mobile" label="Mobile Number" />
                  <MobileNumberInput
                    value={formData.mobile}
                    error={errorData.mobile}
                    onChange={handleMobileChange}
                    placeholder="Enter mobile number"
                  />
                </StyledFormElement>
                <StyledFormElement $hasError={Boolean(errorData.email)}>
                  <Form.FormLabel id="email" label="Email" />
                  <Form.Input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    error={errorData.email}
                    onChange={handleOnChange}
                  />
                </StyledFormElement>
                {isCtcRequired && (
                  <StyledFormElement $hasError={Boolean(errorData.ctc)}>
                    <Form.FormLabel id="ctc" label="Annual CTC (₹)" required />
                    <Form.Input
                      type="number"
                      name="ctc"
                      id="ctc"
                      error={errorData.ctc}
                      value={formData.ctc || ''}
                      onChange={handleOnChange}
                    />
                  </StyledFormElement>
                )}

                {isSpouseMidterm && (
                  <>
                    <StyledFormElementFullWidth
                      $hasError={Boolean(errorData.gender)}
                    >
                      <Form.FormLabel id="gender" label="Gender" required />
                      <StyledGenderElement>
                        {GENDER_DROPDOWN_ITEMS.map((item) => (
                          <Chip
                            label={item.name}
                            key={'MT-' + item.value}
                            isActive={formData.gender === item.value}
                            onClick={() =>
                              handleGenderChange(
                                item.value as 'male' | 'female',
                              )
                            }
                          />
                        ))}
                      </StyledGenderElement>
                      <Typography variant="small" color="error">
                        {errorData.gender}
                      </Typography>
                    </StyledFormElementFullWidth>
                    <StyledFormElementFullWidth
                      $hasError={Boolean(errorData.kycDocumentURLs)}
                    >
                      <Form.FormLabel
                        id="kycDocumentURLs"
                        label="Marriage Certificate"
                        required
                      />
                      <StyledFileSelectionContainer $errored={false}>
                        <UploadPillSelection
                          onUpload={handleSelectedFiles}
                          formats={['.png', '.jpg', '.jpeg', '.pdf']}
                          selected={selectedFiles}
                          onError={(e) => toast?.error(e.message)}
                          maxFiles={1}
                          maxSize={25}
                          onDelete={() => setDeleteFileModalVisible(true)}
                        />
                        <StyledFileSelectionFooter>
                          <Typography variant="extraSmall" color="secondary">
                            Supported Formats: PNG, PDF, JPG
                          </Typography>
                          <Typography variant="extraSmall" color="secondary">
                            Maximum Size: 25MB
                          </Typography>
                        </StyledFileSelectionFooter>
                      </StyledFileSelectionContainer>
                      {errorData.kycDocumentURLs && (
                        <Typography variant="small" color="error">
                          {errorData.kycDocumentURLs}
                        </Typography>
                      )}
                    </StyledFormElementFullWidth>
                  </>
                )}
              </StyledModalBody>
            </StyledBodyWrapper>
          </StyledModalContainer>
          <StyledModalFooter>
            <StyledFooterButton>
              <LoopButton
                variant={isSaveDisabled ? 'disabled' : 'filled'}
                size="medium"
                iconOrder="right"
                isLoading={isValidating}
                onClick={handleSave}
              >
                {mode === OPERATION_MODE.ADD ? buttonText : 'Save'}
              </LoopButton>
            </StyledFooterButton>
          </StyledModalFooter>
        </WithThemeProvider>
      </Modal>
      <Modal
        isVisible={deleteFileModalVisible}
        setIsVisible={setDeleteFileModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Delete Document?"
          description="Are you sure you want to delete this file? You’ll have to re-upload it."
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Delete"
          onPrimaryButtonClicked={confirmDeleteDocument}
          onSecondaryButtonClicked={() => setDeleteFileModalVisible(false)}
          secondaryButtonText="Go Back"
          buttonVariant="error"
        />
      </Modal>
    </>
  );
};

export default AddDependantModal;
