import { Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledErrorAlertWrapper,
  StyledSectionCardContainer,
  StyledSectionContainer,
  StyledSectionLabel,
} from '../styles';
import FilledDependantCard from '../../FilledDependantCard';
import { useDependantsHelper } from '../useDependantsHelper';
import EmptyDependantCard from '../../EmptyDependantCard';
import { generateLabels } from '../../AddDependantModal/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddDependantErrors,
  selectSelectedDependant,
  setSelectedDependant,
} from '../../../../../redux/slices/singleAddSlice';
import AddAlertError from '../../AddAlertError';

const AddEditSelfAndSpouseSection = () => {
  const selectedDependent = useSelector(selectSelectedDependant);
  const dependantErrors = useSelector(selectAddDependantErrors);
  const errorsList = Object.values(dependantErrors).filter((error) => error);
  const dispatch = useDispatch();
  const openDependantModal = (relationship: string) => {
    dispatch(setSelectedDependant(relationship));
  };
  const { dependantsUIMapping } = useDependantsHelper();
  const pageData = dependantsUIMapping['selfAndSpouse'];

  return (
    <StyledSectionContainer>
      {!selectedDependent && errorsList.length > 0 && (
        <StyledErrorAlertWrapper>
          <AddAlertError errors={errorsList} />
        </StyledErrorAlertWrapper>
      )}

      <StyledSectionLabel>
        <Typography variant="small" weight="semiBold">
          {pageData.sectionTitle}
        </Typography>
      </StyledSectionLabel>
      <StyledSectionCardContainer>
        {pageData.dependants.map((dependant, index) =>
          dependant.firstName ? (
            <FilledDependantCard
              disabled={dependant.isAlreadyEnrolled}
              key={index}
              dependant={dependant}
            />
          ) : (
            <EmptyDependantCard
              key={index}
              label={generateLabels(dependant._id || '').cardButtonText}
              onClick={() => openDependantModal(dependant._id || '')}
            />
          ),
        )}
      </StyledSectionCardContainer>
    </StyledSectionContainer>
  );
};

export default AddEditSelfAndSpouseSection;
