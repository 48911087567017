import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { ClaimMilestoneEnum, IClaimTimeline } from '../../../adaptars/claims/types';
import { AlertRedCircle, CrossRedCircle, DashGrayCircle, DisabledGrayCircle, OngoingYellowCircle, TickCircleDarkIcon } from '../../../assets/images';
import { ITimelineElement } from '../ClaimsTimeline/types';
import { IClaimTimelineStatus, IClaimTypes } from './types';

export const processTimelineDataUI = (
    timelineElements: ITimelineElement[],
    claimStatuses: IClaimTimelineStatus[],
): ITimelineElement[] => {
    let defaultTimelineCopy = JSON.parse(JSON.stringify(timelineElements));
    defaultTimelineCopy.forEach((item: ITimelineElement, index: number, object: ITimelineElement[]) => {
        claimStatuses.forEach((rspItem: IClaimTimelineStatus) => {
            if (item.status === rspItem.status) {
                item.active = true;
                item.displayDefault = true;
                item.date = rspItem.date;
                item.url = rspItem?.url;
                if (rspItem.text !== '') {
                    item.description = rspItem.text;
                }
            }

            if (rspItem.status === 'Processed') {
                if (item.id === 'cup') {
                    item.active = true;
                }
                if (item.id === 'cuq') {
                    item.active = false;
                    item.displayDefault = false;
                }
            }
            if (rspItem.status === 'Rejected') {
                let processedIndex = object.findIndex((item: ITimelineElement) => item.id === 'processed');
                if (processedIndex > -1) {
                    object.splice(processedIndex, 1);
                }
                let claimSettledIndex = object.findIndex((item: ITimelineElement) => item.id === 'claim_settled');
                if (claimSettledIndex > -1) {
                    object.splice(claimSettledIndex, 1);
                }
                if (item.id === 'cup') {
                    item.active = true;
                }
            }

            if (rspItem.status === 'Claim Paid') {
                if (item.id === 'cup' || item.id === 'processed') {
                    item.active = true;
                }

                if (item.id === 'cuq') {
                    item.active = false;
                    item.displayDefault = false;
                }
            }

            const lastClaimStatus = claimStatuses[claimStatuses.length - 1];
            if (
                (lastClaimStatus.status === 'Under progress - query' || lastClaimStatus.status === 'Rejected') &&
                rspItem.status === 'Under progress - query'
            ) {
                let cupIndex = object.findIndex((item: ITimelineElement) => item.status === 'Under progress');
                if (cupIndex > -1) {
                    object.splice(cupIndex, 1);
                }
            }
        });
    });
    return defaultTimelineCopy.filter((item: ITimelineElement) => item.displayDefault === true);
};

export const processClaimStatus = (status: ClaimMilestoneEnum, claimType: string): ClaimMilestoneEnum => {
    if (claimType === IClaimTypes.cashless) {
        if (status && status !== ClaimMilestoneEnum.Repudiated) {
            return ClaimMilestoneEnum.Settled;
        }
    }
    return status;
};

export const formatClaimTimelineData = (claimTimeline?: IClaimTimeline) => {
    const formatDate = new DateFormatUtils().formatDate;
    if (!claimTimeline?.timelines || claimTimeline.timelines.length === 0) return [];
    return (
        claimTimeline?.timelines.map((data) => {
            const isUnderQuery = data.history?.[data.history?.length - 1]?.underQuery ?? false
            return ({
                id: data.milestone,
                title: data.title,
                status: data.title,
                description: isUnderQuery
                    ? data.history?.[data.history?.length - 1]?.titleHr
                    : undefined,
                date: data.completed ? formatDate(data.completedDate) : undefined,
                ctaTitle: data.letter ? getLetterCTAFromMilestone(data.milestone) : undefined,
                url: data.letter,
                active: data.ongoing,
                completed: data.completed,
                displayDefault: data.completed,
                icon:
                    isUnderQuery
                        ? AlertRedCircle
                        : data.milestone === ClaimMilestoneEnum.Repudiated
                            ? CrossRedCircle
                            : data.milestone === ClaimMilestoneEnum.Closed
                                ? DashGrayCircle
                                : data.completed
                                    ? TickCircleDarkIcon
                                    : data.ongoing
                                        ? OngoingYellowCircle
                                        : DisabledGrayCircle,
            })
        }) || []
    );
};

export const getLetterCTAFromMilestone = (milestone: ClaimMilestoneEnum) => {
    switch (milestone) {
        case ClaimMilestoneEnum.DocumentsReviewed:
            return 'View query letter';
        case ClaimMilestoneEnum.Settled:
            return 'View settlement letter';
        case ClaimMilestoneEnum.Closed:
            return 'View closure letter';
        case ClaimMilestoneEnum.Repudiated:
            return 'View rejection letter';
    }
}
