import { DateFormatUtils, LoopBadge, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import moment from 'moment';
import { ClaimTagEnum, ClaimUITabsEnum, IClaim } from '../../../adaptars/claims/types';
import { InfoDarkIcon } from '../../../assets/images';
import { IPolicyListItemType } from '../../../redux/slices/hrdRevampRedux/types';
import { isRealtimeTPA } from '../../../utils/common/common';
import { capitalizeFirstLetterOfEachWord, textPlaceholder } from '../../../utils/common/Utilities';
import { IPolicyTypes } from '../../../utils/constants';
import { ALL_CLAIMS_CONSTANT, claimsCountInitialState, claimStatusBadgeVariant } from './constants';
import { StyledClaimAgeHeader, StyledClaimIdUnderline } from './styles';
import { IClaimForTableUI, IClaimStatusTabs } from './types';

export const getFilteredClaims = (
    claims: IClaim[],
    selectedTab: ClaimUITabsEnum,
    policyId: string,
    policyNo: string | null | undefined,
): IClaimForTableUI[] => {
    let filteredClaim: IClaim[] = claims || [];
    if (policyId && policyId !== ALL_CLAIMS_CONSTANT) {
        filteredClaim = claims.filter(
            (claimData) =>
                claimData.policyNo === policyNo &&
                (selectedTab === ALL_CLAIMS_CONSTANT || claimData.tag === selectedTab),
        );
    } else if (selectedTab !== ALL_CLAIMS_CONSTANT) {
        filteredClaim = claims.filter((claimData) => claimData?.tag === selectedTab);
    }
    return transformClaimsListForUI(filteredClaim);
};

export const getClaimsLastUpdatedAt = (claims: IClaim[]): string => {
    const validClaims = claims.filter((claim) => claim.updatedAt);
    if (validClaims.length === 0) return '';
    const latestUpdate = Math.max(
        ...validClaims.map((claim) => new Date(claim.updatedAt).getTime()).filter((time) => !isNaN(time)),
    );
    return new DateFormatUtils().formatDate(new Date(latestUpdate));
};

export const getClaimsCount = (
    claims: IClaim[],
    policyId: string | null,
    policyNo: string | undefined,
): Record<IClaimStatusTabs, number> => {
    const isPolicyFilterNotApplied = policyId === ALL_CLAIMS_CONSTANT;
    const policyFiltered = isPolicyFilterNotApplied
        ? claims
        : claims.filter((claimData) => claimData.policyNo === policyNo);
    const statusClaimsCounts: Record<IClaimStatusTabs, number> = {
        [ALL_CLAIMS_CONSTANT]: policyFiltered.length,
        ...claimsCountInitialState,
    };
    policyFiltered.forEach((claimData) => {
        const status = claimData.tag;
        if (status in statusClaimsCounts && (isPolicyFilterNotApplied || claimData.policyNo === policyNo)) {
            statusClaimsCounts[status]++;
        }
    });
    return statusClaimsCounts;
};

export const CLAIM_LIST_HEADERS = [
    { name: 'Employee Name', flex: 4 },
    { name: 'Emp. ID', flex: 3 },
    { name: 'Request Submission Date', flex: 4 },
    { name: 'Patient Name', flex: 4 },
    { name: 'Relationship', flex: 2 },
    // {name: 'Claim ID', flex: 2},
    { name: 'Claim Age', flex: 2 },
    { name: 'Status', flex: 2 },
];
export const CLAIM_TABLE_PROCESSOR = {
    'Employee Name': {
        cell: function <T>(value: T) {
            return (
                <StyledClaimIdUnderline>
                    <Typography color="emerald" variant="small">
                        {value as string}
                    </Typography>
                </StyledClaimIdUnderline>
            );
        },
    },
    'Claim Age': {
        header: function <T>(value: T) {
            return (
                <StyledClaimAgeHeader>
                    {value as string}
                    <Tooltip
                        beak="top-center"
                        type="informative"
                        text="Claim age is calculated from the day the last documents were submitted by the employee"
                    >
                        <img src={InfoDarkIcon} alt="info icon" />
                    </Tooltip>
                </StyledClaimAgeHeader>
            );
        },
        cell: function <T>(value: T) {
            return <>{(value as number) > -1 ? value + ' Days' : '--'}</>;
        },
    },
    Status: {
        cell: function <T>(value: T, _: number, data: Record<string, unknown>) {
            const badgeType = value ? claimStatusBadgeVariant[value as ClaimTagEnum] : 'grey';
            return (
                <LoopBadge
                    key={data.id as string}
                    badgeType={badgeType}
                    variant={'overflow'}
                    isAnimated={value === ClaimTagEnum.Paid}
                    text={capitalizeFirstLetterOfEachWord(value as string)}
                    fullWidth
                />
            );
        },
    },
};

export const transformClaimsListForUI = (claims: IClaim[]): IClaimForTableUI[] => {
    return claims
        .map((data) => {
            return {
                id: data.id,
                'Employee Name': capitalizeFirstLetterOfEachWord(data.employeeName),
                'Emp. ID': data.employeeCode,
                'Request Submission Date': new DateFormatUtils().formatDate(data.requestSubmissionDate),
                'Patient Name': capitalizeFirstLetterOfEachWord(data.benificiaryName),
                Relationship: capitalizeFirstLetterOfEachWord(data.relationship),
                'Claim ID': data.tpaClaimId ?? '--',
                'Claim Age':
                    data.lastStatusDate && data.claimReceivedAt
                        ? moment(data.lastStatusDate || new Date()).diff(moment(data.claimReceivedAt), 'days')
                        : -1,
                status: data.tag ?? '--',
                Status: data.tag ?? '--',
                'Insurance Type': data.type,
                'Policy Id': data.policyNo,
                userId: data.userId,
            };
        })
        .sort((claim1, claim2) => claim1['Claim Age'] - claim2['Claim Age']);
};

export const getTableHeader = (tab: string, length: number) =>
    `${capitalizeFirstLetterOfEachWord(tab.replace('-', ' ')) + ' Claims'} (${length})`;

export const getPolicyListForClaimsDropDown = (
    policies: IPolicyListItemType[],
    isAllEnabled: boolean = true,
): IDropdownItem[] => {
    const allPoliciesOption = {
        name: 'All Group Medical Cover Policies',
        value: ALL_CLAIMS_CONSTANT,
    };
    const filteredPolicies = policies
        .filter((policy) => policy.policyType === 'GMC')
        .map((item: IPolicyListItemType) => {
            return {
                name: `${
                    IPolicyTypes[item.policyType as keyof typeof IPolicyTypes]
                } | Policy No: ${textPlaceholder(item.policyNumber)} `,
                value: item.id,
                data: {
                    policyType: item.policyType,
                    nickName: item.nickName,
                    name: IPolicyTypes[item.policyType as keyof typeof IPolicyTypes],
                    policyNo: textPlaceholder(item.policyNumber),
                    insurer: item.insurerName,
                },
            };
        });
    return isAllEnabled && filteredPolicies?.length > 1 ? [allPoliciesOption, ...filteredPolicies] : filteredPolicies;
};

export const isSelectedPolicyRealtime = (policies: IPolicyListItemType[], selectedPolicyId?: string) => {
    if (selectedPolicyId === ALL_CLAIMS_CONSTANT) {
        return policies.every((policy) => isRealtimeTPA(policy?.tpaName));
    } else {
        const policy = policies.find((policy) => policy.id === selectedPolicyId);
        return policy ? isRealtimeTPA(policy.insurerName) : false;
    }
};
