import { LogoutAfterInactivity, Toast } from '@loophealth/loop-ui-web-library';
import '@loophealth/loop-ui-web-library/dist/index.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import LoopApiService from './adaptars/LoopApiService';
import { FirebaseAuth } from './adaptars/providers';
import MainRouter from './components/pages/router';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoopApiServiceContext from './contexts/LoopApiServiceContext';
import { cleanup } from './redux/store';
import theme from './theme';
import WithThemeProvider from './theme/WithThemeProvider';
import { SegmentWrapper } from './utils/segment';
import { FlagsmithWrapper } from './utils/flagsmith/FlagsmithWrapper';

const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <Toast.ToastContainer>
        <WithThemeProvider>
          <SegmentWrapper>
            <FlagsmithWrapper>
              <LoopApiServiceContext.Provider value={LoopApiService}>
                <AuthProvider>
                  <SecureAppRouter />
                </AuthProvider>

                <ToastContainer />
              </LoopApiServiceContext.Provider>
            </FlagsmithWrapper>
          </SegmentWrapper>
        </WithThemeProvider>
      </Toast.ToastContainer>
    </ThemeProvider>
  );
};
export default App;

const SecureAppRouter = () => {
  const { logout, setLoggedInUser } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnLogout = () => {
    if (!FirebaseAuth.currentUser) return;
    window.plotline('logout', process.env.REACT_APP_PLOTLINE_KEY!);
    setLoggedInUser(null);
    logout();
    dispatch(cleanup());
    history.push('/login');
  };

  return (
    <LogoutAfterInactivity logoutAction={handleOnLogout}>
      <MainRouter />
    </LogoutAfterInactivity>
  );
};
