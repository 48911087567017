import { IPolicyType } from '../../../containers/PolicyDetailsCoverage/types';
import { IStatus } from '../ListingPage/types';

export interface IEndorsementDetailsResponse {
  endorsement: IEndorsementDetails;
  endoPremiumDetails: IEndorsementPremiumDetails;
  insurerLogo: string;
}

export interface IEndorsementDetails {
  companyName: string;
  insurerName: string;
  policyId: string;
  policyNumber: string;
  policyType: IPolicyType;
  status: string;
  livesCount: number;
  endorsementCost: number;
  createdAt: Date;
  memberData: MemberDataEntity[];
  updatedAt?: string;
  nickName?: string;
  tapOutDate: string | null;
}
export interface MemberDataEntity {
  id: string;
  userId: string;
  operationType: 'ADD' | 'EDIT' | 'DELETE';
  policyStartDate: Date;
  policyEndDate: Date;
  sumInsured: number;
  mobile: string;
  ctc: string;
  rpm: boolean;
  email: string;
  age: number;
  oldSumInsured: number;
  oldAge: number;
  newSumInsured: number;
  newAge: number;
  raterPremium: number;
  proRatedDays: number;
  netPremium: number;
  createdAt: string;
  updatedAt: string;
  dol: string;
  userCreatedAt: string;
  endorsementId: string;
  firstName: string;
  lastName: string;
  relationship: string;
  ageBand: string;
  oldAnnualPremium: number;
  newAnnualPremium: number;
  newProRatedAnnualPremium: number;
  oldProRatedAnnualPremium: number;
  igst: number;
  cgst: number;
  sgst: number;
  totalProRatePremium: number;
  correctionType: string;
  oldData: string;
  revisedData: string;
  employeeId: string;
  gender: string;
  isException: boolean;
  policyNumber: string;
  dob: { _seconds: number };
  doj: { _seconds: number };
  source: string;
}

export interface IEndorsementPremiumDetails {
  additionCost: number;
  cdAccountId: string;
  cdBalance: number;
  deleteCost: number;
  editsCost: number;
  endorsementCost: number;
  refundEditsCost: number;
  totalEditsCost: number;
  endorsementId: string;
  estimatedBalancePostEndo: number;
  netCostOfEndorsement: number;
}

export interface IEndorsementDataForUI {
  policyName: string;
  insurerName: string;
  insurerLogo: string;
  policyId: string;
  memberCount: number;
  memberDetails: Record<string, string | number>;
  endorsementCost: string;
  endorsementCostDetails: Record<string, string | number>;
  status: IStatus;
  policyType: string;
  memberData: IEndoTableEntry[];
  submissionDate: string;
  policyNumber: string;
  nickName: string;
  tapOutDate: string | null;
}

export interface IEndoTableEntry {
  userId: string;
  employeeId: string;
  employeeName: string;
  addedOn: string;
  relationship: string;
  operation: string;
  source: string;
  cost?: string;
}

export const GMC_ENDO_STATUS_LIST: IStatus[] = [
  'DRAFT',
  'IN_PROGRESS',
  'SENT_TO_INSURER',
  'ENDO_RECEIVED',
  'READY_FOR_TPA',
  'RECEIVED_UHIDS',
];

export const OTHER_ENDO_STATUS_LIST: IStatus[] = [
  'DRAFT',
  'IN_PROGRESS',
  'SENT_TO_INSURER',
  'ENDO_RECEIVED',
];

export enum IEndoStatuses {
  DRAFT = 'Data Submission',
  IN_PROGRESS = 'Review by Loop',
  SENT_TO_INSURER = 'Data Sent to Insurer',
  ENDO_DECLINED = 'ENDO_DECLINED',
  ENDO_COMPLETE = 'E-Cards Received',
  READY_FOR_TPA = 'Data Sent to Insurer',
  RECEIVED_UHIDS = 'UHID Generated',
  ENDO_RECEIVED = 'E-Cards Received',
}

export enum IEndoStatesTimeline {
  DRAFT = 'data_submission',
  IN_PROGRESS = 'loop_review',
  SENT_TO_INSURER = 'data_sent',
  READY_FOR_TPA = 'data_sent',
  RECEIVED_UHIDS = 'uhid_generated',
  ENDO_RECEIVED = 'uhid_generated',
  ENDO_DECLINED = 'endo_rejected',
  ENDO_COMPLETE = 'ecard_received',
}

export enum MemberDataOperationType {
  ADD = 'Addition',
  EDIT = 'Edit',
  DELETE = 'Deletion',
}
