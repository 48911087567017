import { useDispatch, useSelector } from 'react-redux';
import LoopApiService from '../../../../../adaptars/LoopApiService';
import {
  selectActiveDependants,
  selectAddDependantErrors,
  selectAddEmployeeDetails,
  selectAddSelectedPolicyDetails,
  selectADSelectedEmployee,
  selectIsMidtermFlow,
  selectSelectedDependant,
  selectSelectedDependantDetails,
  setAddDependantErrors,
  setAddEmployeeDetails,
  setIndividualDependantDetails,
  setSelectedDependant,
} from '../../../../../redux/slices/singleAddSlice';
import { IMidtermRequest } from '../../../MidTermAdditionModal/types';
import { useMemo, useState } from 'react';
import {
  checkIfRequiredFieldsFilledForDep,
  generateLabels,
  isSingleAddMidtermValidated,
  validateDependant,
} from '../utils';
import {
  initialDependantDetails,
  initialDependantErrors,
} from '../../../../../redux/slices/singleAddSlice/initialState';
import { selectSelectedCompany } from '../../../../../redux/slices/hrdRevampRedux';
import {
  IDependant,
  IDependantErrors,
  IEmployeeFormData,
} from '../../../../../redux/slices/singleAddSlice/types';
import {
  findLatestDateFrom,
  uploadUserFileToBucket,
} from '../../../../../utils/common/Utilities';
import { formatMemberName } from '../../../../../utils/common/common';
import {
  checkIfCtcRequired,
  validateDependantDetails,
  validateEmployeeDetails,
} from '../../../../pages/Endorsements/SingleAddMembers/utils';
import { IError } from '../../../../pages/Endorsements/ListingPage/types';
import { Toast } from '@loophealth/loop-ui-web-library';
import { OPERATION_MODE } from '../constants';
import moment from 'moment';
import { IRelationship } from '../../../Bulk/types';
import { deleteMidtermDocument } from '../../../MidTermAdditionModal/utils';

const useDependantModalHelper = () => {
  const dispatch = useDispatch();
  const isMidterm = useSelector(selectIsMidtermFlow);
  const selectedDependant = useSelector(selectSelectedDependant);
  const dependants = useSelector(selectActiveDependants);
  const isOpen = Boolean(selectedDependant);
  const toast = Toast.useToast();
  const reduxFormData = useSelector(selectSelectedDependantDetails);

  const [formData, setFormData] = useState(initialDependantDetails);
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Record<string, unknown>[]>(
    [],
  );
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const employeeDetails = useSelector(selectAddEmployeeDetails);
  const selectedADEmployee = useSelector(selectADSelectedEmployee);
  const errorData = useSelector(selectAddDependantErrors);
  const selectedCompany = useSelector(selectSelectedCompany);
  const isOldEmployeeFlow = selectedADEmployee !== null;

  const gmcPolicy = useMemo(
    () =>
      Object.values(selectedPolicies).find(
        (policy) => policy.policyType === 'GMC' && !policy.isParentalPolicy,
      ),
    [selectedPolicies],
  );

  const companyId = selectedCompany?.id ?? '';

  const setErrorData = (errors: IDependantErrors) => {
    dispatch(setAddDependantErrors(errors));
  };

  const handleSelectedFiles = async (files: Record<string, unknown>[]) => {
    if (!selectedADEmployee) return;
    try {
      if (files.length > 0) {
        const filePath = await uploadUserFileToBucket(
          selectedADEmployee.employeeId,
          formatMemberName(formData.firstName, formData.lastName),
          files[0] as unknown as File,
        );
        setSelectedFiles(files);
        setFormData({
          ...formData,
          kycDocumentURLs: filePath,
          kycDocumentSize: files[0].size as number,
        });
      }
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
  };

  const deleteSelectedFile = () => {
    deleteMidtermDocument(formData.kycDocumentURLs);
    setFormData({
      ...formData,
      kycDocumentURLs: undefined,
      kycDocumentSize: 0,
    });
    setSelectedFiles([]);
  };

  const clearState = () => {
    setFormData(initialDependantDetails);
    setGlobalErrors([]);
    setErrorData(initialDependantErrors);
    setIsValidating(false);
    setIsDataUpdated(false);
    setSelectedFiles([]);
  };

  const closeModal = () => {
    dispatch(setSelectedDependant(null));
    clearState();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorData({
      ...errorData,
      [e.target.name]: '',
    });
    setIsDataUpdated(true);
  };

  const handleMobileChange = (value: string) => {
    setFormData({ ...formData, mobile: value });
    setErrorData({
      ...errorData,
      mobile: '',
    });
    setIsDataUpdated(true);
  };

  const handleDateChange = (
    date: Date | null,
    key: 'dob' | 'dateOfMarriage' | 'policyStartDate',
  ) => {
    setFormData({ ...formData, [key]: date });
    setIsDataUpdated(true);
    setGlobalErrors([]);
  };

  const handleGenderChange = (value: 'male' | 'female') => {
    setFormData({ ...formData, gender: value });
    setErrorData({
      ...errorData,
      gender: '',
    });
    setIsDataUpdated(true);
    setGlobalErrors([]);
  };

  const processDependantDetails = async () => {
    let finalErrors;
    let selfPolicyStartDate = employeeDetails.policyStartDate ?? new Date();
    try {
      if (formData.relationship === 'self') {
        const { errors } = await validateEmployeeDetails(
          formData as IEmployeeFormData,
          selectedPolicies,
          companyId,
        );
        finalErrors = errors;
      } else {
        const dependantData = { ...formData };
        if (isMidterm) {
          if (dependantData.relationship === 'spouse') {
            dependantData['policyStartDate'] = findLatestDateFrom(
              dependantData.dateOfMarriage!,
              selfPolicyStartDate,
            );
          } else if (dependantData.relationship === 'child') {
            dependantData['policyStartDate'] = findLatestDateFrom(
              dependantData.dob!,
              selfPolicyStartDate,
            );
          }
        }
        const { errors } = await validateDependantDetails(
          companyId,
          selectedPolicies,
          { ...employeeDetails },
          [
            dependantData,
            {
              ...employeeDetails,
              relationship: 'self',
            } as IDependant,
          ],
          isOldEmployeeFlow,
        );
        finalErrors = errors;
      }
      const { global, policyStartDate, joiningDate } = finalErrors;
      let tempErrors = [];
      if (global || policyStartDate || joiningDate) {
        if (policyStartDate) {
          tempErrors.push(policyStartDate);
        }
        if (joiningDate && !isEmployee) {
          tempErrors.push(joiningDate);
        }
        if (global) {
          tempErrors.push(global);
        }
        setGlobalErrors(tempErrors);
      } else {
        setGlobalErrors([]);
      }
      setErrorData(finalErrors as unknown as IDependantErrors);

      if (Object.keys(finalErrors).length === 0) {
        dispatch(setIndividualDependantDetails(formData));
        if (formData.relationship === 'self') {
          dispatch(setAddEmployeeDetails(formData as IEmployeeFormData));
        }
        closeModal();
      }
    } catch (e) {
      toast?.error((e as IError).message ?? 'Something went wrong');
    }
  };

  const handleSave = async () => {
    setIsValidating(true);
    const { hasError, errorFields } = validateDependant({
      formData,
      relationship,
      isMidterm,
      isCtcRequired,
      dependants,
    });
    if (hasError) {
      setErrorData(errorFields);
    } else if (
      isMidterm &&
      ['child', 'spouse'].includes(formData.relationship as IRelationship)
    ) {
      const midtermPayload: IMidtermRequest = {
        employeeId: selectedADEmployee?.employeeId,
        dob: moment(formData.dob).format('DD/MM/YYYY'),
        relationship: formData.relationship as IRelationship,
      };
      if (formData.relationship === 'spouse') {
        midtermPayload['dateOfMarriage'] = moment(
          formData.dateOfMarriage,
        ).format('DD/MM/YYYY');
      }
      const { isMidtermValidated, errorMessage } = await validateMidterm(
        midtermPayload,
      );
      if (isMidtermValidated) {
        await processDependantDetails();
      } else {
        setGlobalErrors((state) => [...state, errorMessage]);
      }
    } else {
      await processDependantDetails();
    }
    setIsValidating(false);
  };

  const validateMidterm = async (user: IMidtermRequest) => {
    try {
      const policyId = gmcPolicy?.policyId;
      const midTermResponse = await LoopApiService.validateMidterms(
        {
          add: [user],
          edit: [],
        },
        policyId!,
      );
      const isMidtermValid = isSingleAddMidtermValidated(midTermResponse);
      const error =
        midTermResponse.data?.rejectedMidTermAdditions?.add?.[0]?.error || '';
      return {
        isMidtermValidated: isMidtermValid,
        errorMessage: error,
      };
    } catch (e) {
      return {
        isMidtermValidated: false,
        errorMessage: (e as Error).message || 'Failed to validate midterm',
      };
    }
  };

  const mode = useMemo(() => {
    return reduxFormData?.firstName ? OPERATION_MODE.EDIT : OPERATION_MODE.ADD;
  }, [reduxFormData]);

  const { buttonText, modalTitle, relationship } = useMemo(() => {
    return generateLabels(selectedDependant || 'self', mode);
  }, [selectedDependant, mode]);

  const isCtcRequired = useMemo(
    () => checkIfCtcRequired(selectedPolicies) && relationship === 'self',
    [selectedPolicies, relationship],
  );

  const isEmployee = relationship === 'self';
  const isSpouseMidterm = relationship === 'spouse' && isMidterm;
  const requiredCheck = useMemo(
    () => checkIfRequiredFieldsFilledForDep(formData, isSpouseMidterm),
    [formData, isSpouseMidterm],
  );
  const isSaveDisabled =
    isValidating || !isDataUpdated || globalErrors.length || !requiredCheck;

  return {
    handleSave,
    handleGenderChange,
    handleDateChange,
    handleOnChange,
    handleMobileChange,
    handleSelectedFiles,
    closeModal,
    formData,
    selectedFiles,
    globalErrors,
    buttonText,
    modalTitle,
    isOpen,
    isCtcRequired,
    isEmployee,
    isSpouseMidterm,
    isSaveDisabled,
    errorData,
    mode,
    isValidating,
    reduxFormData,
    setFormData,
    isDataUpdated,
    setIsDataUpdated,
    setSelectedFiles,
    toast,
    deleteSelectedFile,
  };
};

export default useDependantModalHelper;
